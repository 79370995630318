"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateModelsProperty = void 0;
const list_1 = require("../../operations/list");
const indexQuery_1 = require("../../operations/indexQuery");
const get_1 = require("../../operations/get");
const subscription_1 = require("../../operations/subscription");
const observeQuery_1 = require("../../operations/observeQuery");
const clientUtils_1 = require("../../clientUtils");
function generateModelsProperty(client, apiGraphQLConfig, getInternals) {
    const models = {};
    const modelIntrospection = apiGraphQLConfig.modelIntrospection;
    if (!modelIntrospection) {
        return {};
    }
    const SUBSCRIPTION_OPS = ['ONCREATE', 'ONUPDATE', 'ONDELETE'];
    for (const model of Object.values(modelIntrospection.models)) {
        const { name } = model;
        models[name] = {};
        const enabledModelOps = (0, clientUtils_1.excludeDisabledOps)(modelIntrospection, name);
        Object.entries(enabledModelOps).forEach(([key, { operationPrefix }]) => {
            const operation = key;
            if (operation === 'LIST') {
                models[name][operationPrefix] = (0, list_1.listFactory)(client, modelIntrospection, model, getInternals);
            }
            else if (SUBSCRIPTION_OPS.includes(operation)) {
                models[name][operationPrefix] = (0, subscription_1.subscriptionFactory)(client, modelIntrospection, model, operation, getInternals);
            }
            else if (operation === 'OBSERVEQUERY') {
                models[name][operationPrefix] = (0, observeQuery_1.observeQueryFactory)(models, model);
            }
            else {
                models[name][operationPrefix] = (0, get_1.getFactory)(client, modelIntrospection, model, operation, getInternals);
            }
        });
        const secondaryIdxs = (0, clientUtils_1.getSecondaryIndexesFromSchemaModel)(model);
        for (const idx of secondaryIdxs) {
            models[name][idx.queryField] = (0, indexQuery_1.indexQueryFactory)(client, modelIntrospection, model, idx, getInternals);
        }
    }
    return models;
}
exports.generateModelsProperty = generateModelsProperty;
