"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.capitalize = void 0;
/**
 * @param s string to capitalize
 * @returns capitalized string
 */
function capitalize(s) {
    return `${s[0].toUpperCase()}${s.slice(1)}`;
}
exports.capitalize = capitalize;
