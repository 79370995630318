/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const submitInsightFeedback = /* GraphQL */ `mutation SubmitInsightFeedback(
  $insightId: ID!
  $contextId: String
  $documentId: String
  $useful: Boolean
  $accurate: Boolean
  $showMore: Boolean
  $comment: String
) {
  submitInsightFeedback(
    insightId: $insightId
    contextId: $contextId
    documentId: $documentId
    useful: $useful
    accurate: $accurate
    showMore: $showMore
    comment: $comment
  ) {
    id
    name
    contextId
    userId
    documentId
    content {
      ... on ChartContent {
        type
        name
        options
        columns {
          id
          label
          type
          visualization {
            type
            options
            colors
            legends
            __typename
          }
          __typename
        }
        previewImageFallbackIcon
        previewImageUrl
        fragments
      }
      ... on MediaContent {
        videoUrl
        thumbnailUrl
        previewImageFallbackIcon
        previewImageUrl
        fragments
      }
      ... on WebContent {
        url
        embed
        dialogConfig {
          title
          timeout
          width
          height
          horizontalAlign
          verticalAlign
          triggerEvent
          initScript
          __typename
        }
        previewImageFallbackIcon
        previewImageUrl
        fragments
      }
    }
    scope
    createdTs
    lastUpdatedTs
    expirationTs
    title
    description
    productName
    productLineCode
    actions {
      title
      name
      parameters
      contentUrl
      __typename
    }
    summaryActions {
      title
      name
      parameters
      __typename
    }
    fragments
    userList
    visualization
    visualizationOptions {
      options
      colors
      __typename
    }
    visualizationData
    feedback {
      useful
      accurate
      showMore
      comment
      __typename
    }
    feedbackEnabled
    snoozeEnabled
    source
    sourceUuid
    payloadUrl
    categories
    chartData {
      data
      columns {
        id
        label
        type
        visualization {
          type
          options
          colors
          legends
          __typename
        }
        __typename
      }
      series
      donut_chart_data
      __typename
    }
    tealiumTag
    thumbnailUrl
    sample
    stringValues {
      name
      value
      value_type
      __typename
    }
    numericValues {
      name
      value
      value_type
      __typename
    }
    timeSeriesValues {
      name
      value
      __typename
    }
    jsonValues {
      name
      value
      value_type
      __typename
    }
    documentType
    documentSource
    contentName
    additionalPlcs
    additionalUsers {
      userId
      imageUrl
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitInsightFeedbackMutationVariables,
  APITypes.SubmitInsightFeedbackMutation
>;
export const createInsightControl = /* GraphQL */ `mutation CreateInsightControl(
  $insightId: ID
  $contextId: String
  $productLineCode: String
  $controlType: String
  $scope: String
  $ttl: Int
) {
  createInsightControl(
    insightId: $insightId
    contextId: $contextId
    productLineCode: $productLineCode
    controlType: $controlType
    scope: $scope
    ttl: $ttl
  ) {
    id
    insightId
    contextId
    productLineCode
    controlType
    expirationTs
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInsightControlMutationVariables,
  APITypes.CreateInsightControlMutation
>;
export const internal_upsertInsight = /* GraphQL */ `mutation Internal_upsertInsight(
  $id: ID!
  $userId: String
  $contextId: String
  $scope: String
  $lastUpdatedTs: AWSDateTime
) {
  internal_upsertInsight(
    id: $id
    userId: $userId
    contextId: $contextId
    scope: $scope
    lastUpdatedTs: $lastUpdatedTs
  ) {
    id
    name
    contextId
    userId
    documentId
    content {
      ... on ChartContent {
        type
        name
        options
        columns {
          id
          label
          type
          visualization {
            type
            options
            colors
            legends
            __typename
          }
          __typename
        }
        previewImageFallbackIcon
        previewImageUrl
        fragments
      }
      ... on MediaContent {
        videoUrl
        thumbnailUrl
        previewImageFallbackIcon
        previewImageUrl
        fragments
      }
      ... on WebContent {
        url
        embed
        dialogConfig {
          title
          timeout
          width
          height
          horizontalAlign
          verticalAlign
          triggerEvent
          initScript
          __typename
        }
        previewImageFallbackIcon
        previewImageUrl
        fragments
      }
    }
    scope
    createdTs
    lastUpdatedTs
    expirationTs
    title
    description
    productName
    productLineCode
    actions {
      title
      name
      parameters
      contentUrl
      __typename
    }
    summaryActions {
      title
      name
      parameters
      __typename
    }
    fragments
    userList
    visualization
    visualizationOptions {
      options
      colors
      __typename
    }
    visualizationData
    feedback {
      useful
      accurate
      showMore
      comment
      __typename
    }
    feedbackEnabled
    snoozeEnabled
    source
    sourceUuid
    payloadUrl
    categories
    chartData {
      data
      columns {
        id
        label
        type
        visualization {
          type
          options
          colors
          legends
          __typename
        }
        __typename
      }
      series
      donut_chart_data
      __typename
    }
    tealiumTag
    thumbnailUrl
    sample
    stringValues {
      name
      value
      value_type
      __typename
    }
    numericValues {
      name
      value
      value_type
      __typename
    }
    timeSeriesValues {
      name
      value
      __typename
    }
    jsonValues {
      name
      value
      value_type
      __typename
    }
    documentType
    documentSource
    contentName
    additionalPlcs
    additionalUsers {
      userId
      imageUrl
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.Internal_upsertInsightMutationVariables,
  APITypes.Internal_upsertInsightMutation
>;
export const createInsight = /* GraphQL */ `mutation CreateInsight($id: Boolean, $scope: Boolean, $insightProto: String!) {
  createInsight(id: $id, scope: $scope, insightProto: $insightProto) {
    id
    scope
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInsightMutationVariables,
  APITypes.CreateInsightMutation
>;
