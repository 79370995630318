"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.addSchemaToClient = void 0;
const internals_1 = require("./internals");
function addSchemaToClient(client, apiGraphqlConfig, getInternals) {
    (0, internals_1.upgradeClientCancellation)(client);
    client.models = (0, internals_1.generateModelsProperty)(client, apiGraphqlConfig, getInternals);
    client.enums = (0, internals_1.generateEnumsProperty)(apiGraphqlConfig);
    client.queries = (0, internals_1.generateCustomQueriesProperty)(client, apiGraphqlConfig, getInternals);
    client.mutations = (0, internals_1.generateCustomMutationsProperty)(client, apiGraphqlConfig, getInternals);
    client.subscriptions = (0, internals_1.generateCustomSubscriptionsProperty)(client, apiGraphqlConfig, getInternals);
    client.conversations = (0, internals_1.generateConversationsProperty)(client, apiGraphqlConfig, getInternals);
    client.generations = (0, internals_1.generateGenerationsProperty)(client, apiGraphqlConfig, getInternals);
    return client;
}
exports.addSchemaToClient = addSchemaToClient;
