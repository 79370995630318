"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.indexQueryFactory = void 0;
const APIClient_1 = require("../APIClient");
const utils_1 = require("./utils");
const utils_2 = require("../../utils");
const cancellation_1 = require("../cancellation");
function indexQueryFactory(client, modelIntrospection, model, indexMeta, getInternals, context = false) {
    const indexQueryWithContext = (contextSpec, args, options) => {
        return _indexQuery(client, modelIntrospection, model, indexMeta, getInternals, {
            ...args,
            ...options,
        }, contextSpec);
    };
    const indexQuery = (args, options) => {
        return _indexQuery(client, modelIntrospection, model, indexMeta, getInternals, {
            ...args,
            ...options,
        });
    };
    return context ? indexQueryWithContext : indexQuery;
}
exports.indexQueryFactory = indexQueryFactory;
function processGraphQlResponse(modelIntroSchema, modelName, result, selectionSet, modelInitializer) {
    const { data, extensions } = result;
    const [key] = Object.keys(data);
    if (data[key].items) {
        const flattenedResult = data[key].items.map((value) => (0, APIClient_1.flattenItems)(modelIntroSchema, modelName, value));
        return {
            data: selectionSet ? flattenedResult : modelInitializer(flattenedResult),
            nextToken: data[key].nextToken,
            extensions,
        };
    }
    // Index queries are always list queries. No `items`? No flattening needed.
    return {
        data: data[key],
        nextToken: data[key].nextToken,
        extensions,
    };
}
function _indexQuery(client, modelIntrospection, model, indexMeta, getInternals, args, contextSpec) {
    return (0, utils_2.selfAwareAsync)(async (resultPromise) => {
        const { name } = model;
        const query = (0, APIClient_1.generateGraphQLDocument)(modelIntrospection, model, 'INDEX_QUERY', args, indexMeta);
        const variables = (0, APIClient_1.buildGraphQLVariables)(model, 'INDEX_QUERY', args, modelIntrospection, indexMeta);
        const auth = (0, APIClient_1.authModeParams)(client, getInternals, args);
        const modelInitializer = (flattenedResult) => (0, APIClient_1.initializeModel)(client, name, flattenedResult, modelIntrospection, auth.authMode, auth.authToken, !!contextSpec);
        try {
            const headers = (0, APIClient_1.getCustomHeaders)(client, getInternals, args?.headers);
            const graphQlParams = {
                ...auth,
                query,
                variables,
            };
            const requestArgs = [graphQlParams, headers];
            if (contextSpec !== undefined) {
                requestArgs.unshift(contextSpec);
            }
            const basePromise = client.graphql(...requestArgs);
            const extendedPromise = (0, cancellation_1.extendCancellability)(basePromise, resultPromise);
            const response = await extendedPromise;
            if (response.data !== undefined) {
                return processGraphQlResponse(modelIntrospection, name, response, args?.selectionSet, modelInitializer);
            }
        }
        catch (error) {
            /**
             * The `data` type returned by `error` here could be:
             * 1) `null`
             * 2) an empty object
             * 3) "populated" but with a `null` value:
             *   `data: { listByExampleId: null }`
             * 4) an actual record:
             *   `data: { listByExampleId: items: [{ id: '1', ...etc } }]`
             */
            const { data, errors } = error;
            // `data` is not `null`, and is not an empty object:
            if (data !== undefined &&
                data !== null &&
                Object.keys(data).length !== 0 &&
                errors) {
                const [key] = Object.keys(data);
                if (data[key]?.items) {
                    const flattenedResult = data[key]?.items.map((value) => (0, APIClient_1.flattenItems)(modelIntrospection, name, value));
                    /**
                     * Check exists since `flattenedResult` could be `null`.
                     * if `flattenedResult` exists, result is an actual record.
                     */
                    if (flattenedResult) {
                        return {
                            data: args?.selectionSet
                                ? flattenedResult
                                : modelInitializer(flattenedResult),
                            nextToken: data[key]?.nextToken,
                        };
                    }
                }
                // response is of type `data: { listByExampleId: null }`
                return {
                    data: data[key],
                    nextToken: data[key]?.nextToken,
                };
            }
            else {
                // `data` is `null` or an empty object:
                return (0, utils_1.handleListGraphQlError)(error);
            }
        }
    });
}
