// source: protos/grpc_insight.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.autodesk.eccr.grpc_insight.ConnectionRequest', null, global);
goog.exportSymbol('proto.autodesk.eccr.grpc_insight.Empty', null, global);
goog.exportSymbol('proto.autodesk.eccr.grpc_insight.JoinRequest', null, global);
goog.exportSymbol('proto.autodesk.eccr.grpc_insight.JoinResponse', null, global);
goog.exportSymbol('proto.autodesk.eccr.grpc_insight.JoinResult', null, global);
goog.exportSymbol('proto.autodesk.eccr.grpc_insight.LevelFilter', null, global);
goog.exportSymbol('proto.autodesk.eccr.grpc_insight.LogFromClient', null, global);
goog.exportSymbol('proto.autodesk.eccr.grpc_insight.MessageReq', null, global);
goog.exportSymbol('proto.autodesk.eccr.grpc_insight.MessageRes', null, global);
goog.exportSymbol('proto.autodesk.eccr.grpc_insight.ServerMsgStream', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.autodesk.eccr.grpc_insight.LogFromClient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.autodesk.eccr.grpc_insight.LogFromClient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.autodesk.eccr.grpc_insight.LogFromClient.displayName = 'proto.autodesk.eccr.grpc_insight.LogFromClient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.autodesk.eccr.grpc_insight.ConnectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.autodesk.eccr.grpc_insight.ConnectionRequest.displayName = 'proto.autodesk.eccr.grpc_insight.ConnectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.autodesk.eccr.grpc_insight.JoinRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.autodesk.eccr.grpc_insight.JoinRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.autodesk.eccr.grpc_insight.JoinRequest.displayName = 'proto.autodesk.eccr.grpc_insight.JoinRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.autodesk.eccr.grpc_insight.ServerMsgStream = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.autodesk.eccr.grpc_insight.ServerMsgStream, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.autodesk.eccr.grpc_insight.ServerMsgStream.displayName = 'proto.autodesk.eccr.grpc_insight.ServerMsgStream';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.autodesk.eccr.grpc_insight.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.autodesk.eccr.grpc_insight.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.autodesk.eccr.grpc_insight.Empty.displayName = 'proto.autodesk.eccr.grpc_insight.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.autodesk.eccr.grpc_insight.MessageReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.autodesk.eccr.grpc_insight.MessageReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.autodesk.eccr.grpc_insight.MessageReq.displayName = 'proto.autodesk.eccr.grpc_insight.MessageReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.autodesk.eccr.grpc_insight.MessageRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.autodesk.eccr.grpc_insight.MessageRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.autodesk.eccr.grpc_insight.MessageRes.displayName = 'proto.autodesk.eccr.grpc_insight.MessageRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.autodesk.eccr.grpc_insight.JoinResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.autodesk.eccr.grpc_insight.JoinResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.autodesk.eccr.grpc_insight.JoinResult.displayName = 'proto.autodesk.eccr.grpc_insight.JoinResult';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.autodesk.eccr.grpc_insight.LogFromClient.prototype.toObject = function(opt_includeInstance) {
  return proto.autodesk.eccr.grpc_insight.LogFromClient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.autodesk.eccr.grpc_insight.LogFromClient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.eccr.grpc_insight.LogFromClient.toObject = function(includeInstance, msg) {
  var f, obj = {
    oxygenId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    content: jspb.Message.getFieldWithDefault(msg, 4, ""),
    levelFilter: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.autodesk.eccr.grpc_insight.LogFromClient}
 */
proto.autodesk.eccr.grpc_insight.LogFromClient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.autodesk.eccr.grpc_insight.LogFromClient;
  return proto.autodesk.eccr.grpc_insight.LogFromClient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.autodesk.eccr.grpc_insight.LogFromClient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.autodesk.eccr.grpc_insight.LogFromClient}
 */
proto.autodesk.eccr.grpc_insight.LogFromClient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOxygenId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientUuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 5:
      var value = /** @type {!proto.autodesk.eccr.grpc_insight.LevelFilter} */ (reader.readEnum());
      msg.setLevelFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.autodesk.eccr.grpc_insight.LogFromClient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.autodesk.eccr.grpc_insight.LogFromClient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.autodesk.eccr.grpc_insight.LogFromClient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.eccr.grpc_insight.LogFromClient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOxygenId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLevelFilter();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string oxygen_id = 1;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.LogFromClient.prototype.getOxygenId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.LogFromClient} returns this
 */
proto.autodesk.eccr.grpc_insight.LogFromClient.prototype.setOxygenId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_uuid = 2;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.LogFromClient.prototype.getClientUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.LogFromClient} returns this
 */
proto.autodesk.eccr.grpc_insight.LogFromClient.prototype.setClientUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_name = 3;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.LogFromClient.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.LogFromClient} returns this
 */
proto.autodesk.eccr.grpc_insight.LogFromClient.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string content = 4;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.LogFromClient.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.LogFromClient} returns this
 */
proto.autodesk.eccr.grpc_insight.LogFromClient.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional LevelFilter level_filter = 5;
 * @return {!proto.autodesk.eccr.grpc_insight.LevelFilter}
 */
proto.autodesk.eccr.grpc_insight.LogFromClient.prototype.getLevelFilter = function() {
  return /** @type {!proto.autodesk.eccr.grpc_insight.LevelFilter} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.autodesk.eccr.grpc_insight.LevelFilter} value
 * @return {!proto.autodesk.eccr.grpc_insight.LogFromClient} returns this
 */
proto.autodesk.eccr.grpc_insight.LogFromClient.prototype.setLevelFilter = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.autodesk.eccr.grpc_insight.ConnectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.autodesk.eccr.grpc_insight.ConnectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    oxygenId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    productVersion: jspb.Message.getFieldWithDefault(msg, 5, ""),
    productBuild: jspb.Message.getFieldWithDefault(msg, 6, ""),
    productLocale: jspb.Message.getFieldWithDefault(msg, 7, ""),
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.autodesk.eccr.grpc_insight.ConnectionRequest}
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.autodesk.eccr.grpc_insight.ConnectionRequest;
  return proto.autodesk.eccr.grpc_insight.ConnectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.autodesk.eccr.grpc_insight.ConnectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.autodesk.eccr.grpc_insight.ConnectionRequest}
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOxygenId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientUuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductVersion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductBuild(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductLocale(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.autodesk.eccr.grpc_insight.ConnectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.autodesk.eccr.grpc_insight.ConnectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOxygenId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProductVersion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProductBuild();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProductLocale();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string oxygen_id = 1;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.getOxygenId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.ConnectionRequest} returns this
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.setOxygenId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_uuid = 2;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.getClientUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.ConnectionRequest} returns this
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.setClientUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_name = 3;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.ConnectionRequest} returns this
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string product_name = 4;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.ConnectionRequest} returns this
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string product_version = 5;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.getProductVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.ConnectionRequest} returns this
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.setProductVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string product_build = 6;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.getProductBuild = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.ConnectionRequest} returns this
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.setProductBuild = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string product_locale = 7;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.getProductLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.ConnectionRequest} returns this
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.setProductLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.autodesk.eccr.grpc_insight.ConnectionRequest} returns this
*/
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.autodesk.eccr.grpc_insight.ConnectionRequest} returns this
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.autodesk.eccr.grpc_insight.ConnectionRequest.prototype.hasTime = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.autodesk.eccr.grpc_insight.JoinRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.autodesk.eccr.grpc_insight.JoinRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    oxygenId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    productVersion: jspb.Message.getFieldWithDefault(msg, 5, ""),
    productBuild: jspb.Message.getFieldWithDefault(msg, 6, ""),
    productLocale: jspb.Message.getFieldWithDefault(msg, 7, ""),
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.autodesk.eccr.grpc_insight.JoinRequest}
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.autodesk.eccr.grpc_insight.JoinRequest;
  return proto.autodesk.eccr.grpc_insight.JoinRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.autodesk.eccr.grpc_insight.JoinRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.autodesk.eccr.grpc_insight.JoinRequest}
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOxygenId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientUuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductVersion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductBuild(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductLocale(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.autodesk.eccr.grpc_insight.JoinRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.autodesk.eccr.grpc_insight.JoinRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOxygenId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProductVersion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProductBuild();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProductLocale();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string oxygen_id = 1;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.getOxygenId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.JoinRequest} returns this
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.setOxygenId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client_uuid = 2;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.getClientUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.JoinRequest} returns this
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.setClientUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_name = 3;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.JoinRequest} returns this
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string product_name = 4;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.JoinRequest} returns this
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string product_version = 5;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.getProductVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.JoinRequest} returns this
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.setProductVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string product_build = 6;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.getProductBuild = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.JoinRequest} returns this
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.setProductBuild = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string product_locale = 7;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.getProductLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.JoinRequest} returns this
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.setProductLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.autodesk.eccr.grpc_insight.JoinRequest} returns this
*/
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.autodesk.eccr.grpc_insight.JoinRequest} returns this
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.autodesk.eccr.grpc_insight.JoinRequest.prototype.hasTime = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.autodesk.eccr.grpc_insight.ServerMsgStream.prototype.toObject = function(opt_includeInstance) {
  return proto.autodesk.eccr.grpc_insight.ServerMsgStream.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.autodesk.eccr.grpc_insight.ServerMsgStream} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.eccr.grpc_insight.ServerMsgStream.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serverInfo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    insight: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.autodesk.eccr.grpc_insight.ServerMsgStream}
 */
proto.autodesk.eccr.grpc_insight.ServerMsgStream.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.autodesk.eccr.grpc_insight.ServerMsgStream;
  return proto.autodesk.eccr.grpc_insight.ServerMsgStream.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.autodesk.eccr.grpc_insight.ServerMsgStream} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.autodesk.eccr.grpc_insight.ServerMsgStream}
 */
proto.autodesk.eccr.grpc_insight.ServerMsgStream.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServerInfo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInsight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.autodesk.eccr.grpc_insight.ServerMsgStream.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.autodesk.eccr.grpc_insight.ServerMsgStream.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.autodesk.eccr.grpc_insight.ServerMsgStream} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.eccr.grpc_insight.ServerMsgStream.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServerInfo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInsight();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string client_uuid = 1;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.ServerMsgStream.prototype.getClientUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.ServerMsgStream} returns this
 */
proto.autodesk.eccr.grpc_insight.ServerMsgStream.prototype.setClientUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string server_info = 2;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.ServerMsgStream.prototype.getServerInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.ServerMsgStream} returns this
 */
proto.autodesk.eccr.grpc_insight.ServerMsgStream.prototype.setServerInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string insight = 3;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.ServerMsgStream.prototype.getInsight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.ServerMsgStream} returns this
 */
proto.autodesk.eccr.grpc_insight.ServerMsgStream.prototype.setInsight = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.autodesk.eccr.grpc_insight.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.autodesk.eccr.grpc_insight.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.autodesk.eccr.grpc_insight.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.eccr.grpc_insight.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.autodesk.eccr.grpc_insight.Empty}
 */
proto.autodesk.eccr.grpc_insight.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.autodesk.eccr.grpc_insight.Empty;
  return proto.autodesk.eccr.grpc_insight.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.autodesk.eccr.grpc_insight.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.autodesk.eccr.grpc_insight.Empty}
 */
proto.autodesk.eccr.grpc_insight.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.autodesk.eccr.grpc_insight.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.autodesk.eccr.grpc_insight.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.autodesk.eccr.grpc_insight.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.eccr.grpc_insight.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.autodesk.eccr.grpc_insight.MessageReq.prototype.toObject = function(opt_includeInstance) {
  return proto.autodesk.eccr.grpc_insight.MessageReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.autodesk.eccr.grpc_insight.MessageReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.eccr.grpc_insight.MessageReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    messagedata: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.autodesk.eccr.grpc_insight.MessageReq}
 */
proto.autodesk.eccr.grpc_insight.MessageReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.autodesk.eccr.grpc_insight.MessageReq;
  return proto.autodesk.eccr.grpc_insight.MessageReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.autodesk.eccr.grpc_insight.MessageReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.autodesk.eccr.grpc_insight.MessageReq}
 */
proto.autodesk.eccr.grpc_insight.MessageReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessagedata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.autodesk.eccr.grpc_insight.MessageReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.autodesk.eccr.grpc_insight.MessageReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.autodesk.eccr.grpc_insight.MessageReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.eccr.grpc_insight.MessageReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessagedata();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.MessageReq.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.MessageReq} returns this
 */
proto.autodesk.eccr.grpc_insight.MessageReq.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string messageData = 2;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.MessageReq.prototype.getMessagedata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.MessageReq} returns this
 */
proto.autodesk.eccr.grpc_insight.MessageReq.prototype.setMessagedata = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.autodesk.eccr.grpc_insight.MessageRes.prototype.toObject = function(opt_includeInstance) {
  return proto.autodesk.eccr.grpc_insight.MessageRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.autodesk.eccr.grpc_insight.MessageRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.eccr.grpc_insight.MessageRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    returncode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    responsedata: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.autodesk.eccr.grpc_insight.MessageRes}
 */
proto.autodesk.eccr.grpc_insight.MessageRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.autodesk.eccr.grpc_insight.MessageRes;
  return proto.autodesk.eccr.grpc_insight.MessageRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.autodesk.eccr.grpc_insight.MessageRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.autodesk.eccr.grpc_insight.MessageRes}
 */
proto.autodesk.eccr.grpc_insight.MessageRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReturncode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponsedata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.autodesk.eccr.grpc_insight.MessageRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.autodesk.eccr.grpc_insight.MessageRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.autodesk.eccr.grpc_insight.MessageRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.eccr.grpc_insight.MessageRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReturncode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getResponsedata();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 returnCode = 1;
 * @return {number}
 */
proto.autodesk.eccr.grpc_insight.MessageRes.prototype.getReturncode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.autodesk.eccr.grpc_insight.MessageRes} returns this
 */
proto.autodesk.eccr.grpc_insight.MessageRes.prototype.setReturncode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string responseData = 2;
 * @return {string}
 */
proto.autodesk.eccr.grpc_insight.MessageRes.prototype.getResponsedata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.eccr.grpc_insight.MessageRes} returns this
 */
proto.autodesk.eccr.grpc_insight.MessageRes.prototype.setResponsedata = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.autodesk.eccr.grpc_insight.JoinResult.prototype.toObject = function(opt_includeInstance) {
  return proto.autodesk.eccr.grpc_insight.JoinResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.autodesk.eccr.grpc_insight.JoinResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.eccr.grpc_insight.JoinResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, 0),
    response: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.autodesk.eccr.grpc_insight.JoinResult}
 */
proto.autodesk.eccr.grpc_insight.JoinResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.autodesk.eccr.grpc_insight.JoinResult;
  return proto.autodesk.eccr.grpc_insight.JoinResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.autodesk.eccr.grpc_insight.JoinResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.autodesk.eccr.grpc_insight.JoinResult}
 */
proto.autodesk.eccr.grpc_insight.JoinResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {!proto.autodesk.eccr.grpc_insight.JoinResponse} */ (reader.readEnum());
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.autodesk.eccr.grpc_insight.JoinResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.autodesk.eccr.grpc_insight.JoinResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.autodesk.eccr.grpc_insight.JoinResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.eccr.grpc_insight.JoinResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getResponse();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional uint64 token = 1;
 * @return {number}
 */
proto.autodesk.eccr.grpc_insight.JoinResult.prototype.getToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.autodesk.eccr.grpc_insight.JoinResult} returns this
 */
proto.autodesk.eccr.grpc_insight.JoinResult.prototype.setToken = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional JoinResponse response = 2;
 * @return {!proto.autodesk.eccr.grpc_insight.JoinResponse}
 */
proto.autodesk.eccr.grpc_insight.JoinResult.prototype.getResponse = function() {
  return /** @type {!proto.autodesk.eccr.grpc_insight.JoinResponse} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.autodesk.eccr.grpc_insight.JoinResponse} value
 * @return {!proto.autodesk.eccr.grpc_insight.JoinResult} returns this
 */
proto.autodesk.eccr.grpc_insight.JoinResult.prototype.setResponse = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.autodesk.eccr.grpc_insight.LevelFilter = {
  OFF: 0,
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  DEBUG: 4,
  TRACE: 5
};

/**
 * @enum {number}
 */
proto.autodesk.eccr.grpc_insight.JoinResponse = {
  DENIED: 0,
  ACCEPTED: 1,
  UNKNOWN: 3
};

goog.object.extend(exports, proto.autodesk.eccr.grpc_insight);
