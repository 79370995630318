/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as fm from "../fetch.pb"
import * as GoogleProtobufTimestamp from "../google/protobuf/timestamp.pb"

export enum LevelFilter {
  Off = "Off",
  Error = "Error",
  Warn = "Warn",
  Info = "Info",
  Debug = "Debug",
  Trace = "Trace",
}

export enum JoinResponse {
  DENIED = "DENIED",
  ACCEPTED = "ACCEPTED",
  UNKNOWN = "UNKNOWN",
}

export type LogFromClient = {
  oxygenId?: string
  clientUuid?: string
  userName?: string
  content?: string
  levelFilter?: LevelFilter
}

export type ConnectionRequest = {
  oxygenId?: string
  clientUuid?: string
  userName?: string
  productName?: string
  productVersion?: string
  productBuild?: string
  productLocale?: string
  time?: GoogleProtobufTimestamp.Timestamp
}

export type JoinRequest = {
  oxygenId?: string
  clientUuid?: string
  userName?: string
  productName?: string
  productVersion?: string
  productBuild?: string
  productLocale?: string
  time?: GoogleProtobufTimestamp.Timestamp
}

export type ServerMsgStream = {
  clientUuid?: string
  serverInfo?: string
  insight?: string
}

export type Empty = {
}

export type MessageReq = {
  message?: string
  messageData?: string
}

export type MessageRes = {
  returnCode?: number
  responseData?: string
}

export type JoinResult = {
  token?: string
  response?: JoinResponse
}

export class GrpcInsight {
  static Join(req: JoinRequest, initReq?: fm.InitReq): Promise<JoinResult> {
    return fm.fetchReq<JoinRequest, JoinResult>(`/autodesk.eccr.grpc_insight.GrpcInsight/Join`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static ConnectServer(req: ConnectionRequest, entityNotifier?: fm.NotifyStreamEntityArrival<ServerMsgStream>, initReq?: fm.InitReq): Promise<void> {
    return fm.fetchStreamingRequest<ConnectionRequest, ServerMsgStream>(`/autodesk.eccr.grpc_insight.GrpcInsight/ConnectServer`, entityNotifier, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static RecordLog(req: LogFromClient, initReq?: fm.InitReq): Promise<Empty> {
    return fm.fetchReq<LogFromClient, Empty>(`/autodesk.eccr.grpc_insight.GrpcInsight/RecordLog`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static ProcessMessage(req: MessageReq, initReq?: fm.InitReq): Promise<MessageRes> {
    return fm.fetchReq<MessageReq, MessageRes>(`/autodesk.eccr.grpc_insight.GrpcInsight/ProcessMessage`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}