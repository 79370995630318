import { Insight as InsightData } from './../../generatedProtoBindings/protos/insight_pb'
import { CONSTANTS, ENV } from '../../constants/constants'
import { CreateError } from '../../utilities/utils'

export class RestClient {
  private static _endpoint: string

  public static Configure(env: ENV) {
    try {
      if (env === ENV.PRODUCTION) {
        this._endpoint = CONSTANTS.PRODUCTION_REST_ENDPOINT
      } else {
        this._endpoint = CONSTANTS.STAGE_REST_ENDPOINT
      }
    } catch (error) {
       throw CreateError('Error while configuring REST client', error)
    }
  }

  public static async SubmitInsight(
    insight: InsightData,
    token: string
  ): Promise<Response> {
    try {
      return await fetch(`${this._endpoint}/insights?details=true`, {
        method: 'PUT',
        credentials: 'omit',
        headers: {
          'x-ads-token-data': '{}',
          'Content-Type': 'application/x-protobuf',
          Authorization: token,
        },
        body: insight.serializeBinary(),
      })
    } catch (error) {
       throw CreateError('Error while submitting insight', error)
    }
  }
}
