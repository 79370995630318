// import {GrpcInsight,ConfigReq} from '../../generatedProtoBindings/protos/grpc_insight_pb';

import { CONSTANTS, ENV } from './../../constants/constants'

import { CreateError } from '../../utilities/utils'
import {
  GrpcInsight,
  MessageReq,
  MessageRes,
  LogFromClient,
} from '../../generatedProtoBindings/protos/grpc_insight.pb'
import { ICallbackType } from '../../interfaces/ICallback'

export class GatewayClient {
  private static _port: string = 'NA'
  public static _foreverLoopIntervalHandle: any

  public static GetSignalTimeout(timeout: number) {
    return AbortSignal.timeout(timeout)
  }

  public static async ProcessMessage(
    message: any,
    messageData: any,
    timeout?: number
  ): Promise<any> {
    let req: MessageReq = {
      message: message,
      messageData: messageData,
    }
    return await GrpcInsight.ProcessMessage(req, {
      // Timout the request after 6 seconds if no response is received from the server. GRPC does not provide a timeout option, so we are using the fetch timeout option
      signal: GatewayClient.GetSignalTimeout(
        timeout || CONSTANTS.GATEWAY_SIGNAL_TIMEOUT
      ),
      pathPrefix: CONSTANTS.GATEWAY_ENDPOINT + this._port,
    })
  }

  public static async RecordLog(
    title: string,
    content: string,
    oxygenId: string,
    levelFilter: any,
    timeout?: number
  ): Promise<any> {
    let logReq: LogFromClient = {
      oxygenId: oxygenId,
      clientUuid: 'NA',
      userName: 'NA',
      content: content,
      levelFilter: levelFilter,
    }
    return await GrpcInsight.RecordLog(logReq, {
      // Timout the request after 6 seconds if no response is received from the server. GRPC does not provide a timeout option, so we are using the fetch timeout option
      signal: GatewayClient.GetSignalTimeout(
        timeout || CONSTANTS.GATEWAY_SIGNAL_TIMEOUT
      ),
      pathPrefix: CONSTANTS.GATEWAY_ENDPOINT + this._port,
    })
  }

  private static async RunPortScan(instance: any) {
    if (await this.checkServerAvailablityOnPorts()) {
      instance.state.gRPCStatus.isServerAvailable = true
      instance.TriggerCallback(ICallbackType.STATUS, instance.state)
    } else {
      instance.state.gRPCStatus.isServerAvailable = false
      instance.TriggerCallback(ICallbackType.STATUS, instance.state)
    }
  }

  public static async InitGatewayClientConfigForeverLoop(instance: any) {
    // This function will run forever from the inception of the application to end
    // If server is not available, it will keep on checking the server on the ports every 3 minutes
    // if server is available, it will stop the loop but it will again check the server status after 3 minutes

    // Talking this approach to avoid complexities of handling the server status in the application and main index

    // Code block Check - Execute only once starts here
    // Adding 10 sec delay in a hope that gRPC server will start running in 10 secs after EnableInsight call
    setTimeout(async function () {
      //This code will get executed after  CONSTANTS.GATEWAY_CONNECTION_DELAY milliseconds only one time
      // and then it will keep on checking the server status after every 1 minutes as per the setInterval ( HeartBeat <3 )
      await GatewayClient.RunPortScan(instance)
    }, CONSTANTS.GATEWAY_CONNECTION_DELAY)

    // Code block Check - Execute only once ends here

    this._foreverLoopIntervalHandle = setInterval(async () => {
      if (instance.state.gRPCStatus.isServerAvailable === false) {
        await GatewayClient.RunPortScan(instance)
      } else {
        // Try pinging server to make sure IDC is really available
        await this.ProcessMessage('Ping', 'Magic string')
          .then((response) => {
            if (response.returnCode === 0) {
              try {
                let data = JSON.parse(response.responseData)
                if (
                  data['pong'] ===
                  'Magic-string:959825be-6e24-43dd-bfab-9e9c6ff40076' // DO NOT CHANGE THIS MAGIC STRING, THIS IS USED TO CHECK THE SERVER AVAILABILITY AND IS COMING FROM IDC
                ) {
                  // DO NOTHING AS SERVER IS AVAILABLE AND WE HAVE ALREADY SET THE STATE TO TRUE
                } else {
                  instance.state.gRPCStatus.isServerAvailable = false
                  instance.TriggerCallback(ICallbackType.STATUS, instance.state)
                }
              } catch (error) {
                // Do nothing
                instance.state.gRPCStatus.isServerAvailable = false
              }
            }
          })
          .catch((error) => {
            instance.state.gRPCStatus.isServerAvailable = false
            instance.TriggerCallback(ICallbackType.STATUS, instance.state)
          })
        // Since we have set the state to false, we will again check the server status after 3 minutes
      }
    }, CONSTANTS.GATEWAY_CHECK_LOOP_INTERVAL)
  }

  private static async checkServerAvailablityOnPorts() {
    var is_Server_Available: Boolean = false
    for (
      let index = CONSTANTS.GATEWAY_START_SCAN_PORT;
      index <= CONSTANTS.GATEWAY_END_SCAN_PORT;
      index++
    ) {
      this._port = index.toString()
      await this.ProcessMessage('Ping', 'Request magic string')
        .then((response) => {
          if (response.returnCode === 0) {
            try {
              let data = JSON.parse(response.responseData)
              if (
                data['pong'] ===
                'Magic-string:959825be-6e24-43dd-bfab-9e9c6ff40076' // DO NOT CHANGE THIS MAGIC STRING, THIS IS USED TO CHECK THE SERVER AVAILABILITY AND IS COMING FROM IDC
              ) {
                is_Server_Available = true
              } else {
                is_Server_Available = false
              }
            } catch (error) {
              // Do nothing
              is_Server_Available = false
            }
          }
        })
        .catch((error) => {
          console.log('Error in checking server availability on port: ' + error)
        })

      if (is_Server_Available) {
        break
      }
    }
    return is_Server_Available
  }

  public static async ClearForeverLoop() {
    clearInterval(this._foreverLoopIntervalHandle)
  }
}
