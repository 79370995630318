// @ts-ignore:next-line
import { isJwtExpired } from 'jwt-check-expiration'
import { JSON_FIELDS } from './../constants/constants'
import { jwtDecode } from 'jwt-decode'

import { Buffer } from 'buffer'
window.Buffer = Buffer

import { instance } from '../main'
import { LevelFilter } from '../generatedProtoBindings/protos/grpc_insight_pb'
export function CreateError(errorName: string, errorMessage: any) {
  
  var errorObj = {
    name: errorName,
    message: errorMessage.message,
    stack: errorMessage.stack,
    details: ''
  }
  if (errorMessage.errors) {
    errorObj.message = errorMessage.errors[0].message
  }
  if (errorObj.message === 'Unknown error' && errorMessage.errors[0]?.originalError?._response) {
    errorObj.details = JSON.stringify(errorMessage.errors[0]?.originalError?._response);
  } else if (!errorMessage.errors[0]?.originalError?._response) {
    errorObj.details = "No connection to server"
  }
  console.log(errorObj);

  instance?.RecordLog(
    'Error:' + errorName,
    JSON.stringify(errorObj),
    LevelFilter.ERROR
  )

  return errorObj
}
export function isTokenExpired(token: string) {
  if (isJwtExpired(token)) {
     CreateError('Error', 'Token is expired')
     throw new Error('Token is expired')
  }
}

export function graphQLOperationOptions(
  customQuery: any,
  customVariables: any,
  token: string,
  mode: any
) {
  return {
    query: customQuery,
    variables: customVariables,
    authMode: mode,
    authToken: token,
  }
}

export function graphQLOperationAdditionalOptions(userID: string) {
  return { 'x-user-id': userID }
}

/* eslint-disable no-console */
export function decodeJSONFields(obj: any, includeAll: any) {
  if (Array.isArray(obj)) {
    for (const insight of obj) {
      decodeJSONFields(insight, includeAll)
    }
    return
  }
  for (const [key, value] of Object.entries(obj)) {
    if (!value) continue
    if (includeAll || JSON_FIELDS.includes(key)) {
      if (typeof value === 'object') {
        decodeJSONFields(value, true)
      } else if (
        typeof value === 'string' &&
        ((value.startsWith('{') && value.endsWith('}')) ||
          (value.startsWith('[') && value.endsWith(']')))
      ) {
        try {
          obj[key] = JSON.parse(value)
        } catch (e) {
          // Ignore
        }
      }
    }
  }
}

export function getOxygenID(token) {
  if (isJwtExpired(token)) {
    return null
  } else {
    const decodedToken = jwtDecode(token)
    return decodedToken['userid']
  }
}

// export const retry = async (fn, maxAttempts) => {
//   const execute = async (attempt) => {
//     try {
//       return await fn();
//     } catch (err) {
//       if (attempt <= maxAttempts) {
//         const nextAttempt = attempt + 1;
//         const delayInSeconds = Math.max(
//           Math.min(
//             2 ** nextAttempt + randInt(-nextAttempt, nextAttempt),
//             600,
//           ),
//           1,
//         );
//         console.error(`Retrying after ${delayInSeconds} seconds due to:`, err);
//         return delay(() => execute(nextAttempt), delayInSeconds * 1000);
//       } else {
//         throw err;
//       }
//     }
//   };
//   return execute(1);
// };
//
// const delay = (fn, ms) =>
//   new Promise((resolve) => setTimeout(() => resolve(fn()), ms));
//
// const randInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
//
