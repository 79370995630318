"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateGenerationsProperty = void 0;
const custom_1 = require("../../operations/custom");
const getCustomUserAgentDetails_1 = require("../../ai/getCustomUserAgentDetails");
function generateGenerationsProperty(client, apiGraphQLConfig, getInternals) {
    const modelIntrospection = apiGraphQLConfig?.modelIntrospection;
    // generations will be absent from model intro schema if no generation routes
    // are present on the source schema.
    if (!modelIntrospection?.generations) {
        return {};
    }
    const generations = {};
    for (const generation of Object.values(modelIntrospection.generations)) {
        generations[generation.name] = (0, custom_1.customOpFactory)(client, modelIntrospection, 'query', generation, false, getInternals, (0, getCustomUserAgentDetails_1.getCustomUserAgentDetails)(getCustomUserAgentDetails_1.AiAction.Generation));
    }
    return generations;
}
exports.generateGenerationsProperty = generateGenerationsProperty;
