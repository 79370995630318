import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import envConfig from 'environmentConfig';
import Collaborate from '../Collaborate';
import Header from '../Header';
import adpInstance from '../../utils/adp';

import host from '../../services/HostWrapper';

import { DeleteModal } from '../Modal';
import { ViewWrapper } from './styled';
import useShallowStore from '../../hooks/useShallowStore';
import useSharesUpdate from '../../hooks/useSharesUpdate';
import { setStartUploadFunction } from '../../utils/hostObjectUtils';

const App = () => {
  const { user } = useShallowStore((state) => ({
    user: state.user,
  }));

  const updateShares = useSharesUpdate();
  const navigate = useNavigate();

  if (typeof updateShares !== 'undefined') {
    setStartUploadFunction({ onUpload: updateShares });
  }

  useEffect(() => {
    host.setLoginStatusChangedCallback(() => navigate('/'));
  }, [navigate]);

  useEffect(() => {
    adpInstance.initializeTrackingClient();
  }, [user]);

  return (
    <>
      <Header />
      <>
        <Collaborate />
        <ViewWrapper>
          <Outlet />
        </ViewWrapper>
        <DeleteModal />
      </>
    </>
  );
};

const AppWithLDProvider = () => {
  const { ldUser } = useShallowStore((state) => ({
    ldUser: state.ldUser,
  }));
  return withLDProvider({
    clientSideID: envConfig.get().featureFlagClientId,
    context: {
      kind: 'user',
      ...ldUser,
      _meta: {
        privateAttributes: ['email'],
      },
    },
  })(App)();
};

export default AppWithLDProvider;
