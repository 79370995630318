"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFactory = void 0;
const APIClient_1 = require("../APIClient");
const utils_1 = require("./utils");
const utils_2 = require("../../utils");
const cancellation_1 = require("../cancellation");
const getCustomUserAgentDetails_1 = require("../ai/getCustomUserAgentDetails");
function getFactory(client, modelIntrospection, model, operation, getInternals, useContext = false, customUserAgentDetails) {
    const getWithContext = (contextSpec, arg, options) => {
        return _get(client, modelIntrospection, model, arg, options, operation, getInternals, contextSpec, customUserAgentDetails);
    };
    const get = (arg, options) => {
        return _get(client, modelIntrospection, model, arg, options, operation, getInternals, undefined, customUserAgentDetails);
    };
    return useContext ? getWithContext : get;
}
exports.getFactory = getFactory;
function _get(client, modelIntrospection, model, arg, options, operation, getInternals, context, customUserAgentDetails) {
    return (0, utils_2.selfAwareAsync)(async (resultPromise) => {
        const { name } = model;
        const query = (0, APIClient_1.generateGraphQLDocument)(modelIntrospection, model, operation, options);
        const variables = (0, APIClient_1.buildGraphQLVariables)(model, operation, arg, modelIntrospection);
        const auth = (0, APIClient_1.authModeParams)(client, getInternals, options);
        const headers = (0, APIClient_1.getCustomHeaders)(client, getInternals, options?.headers);
        const userAgentOverride = (0, getCustomUserAgentDetails_1.createUserAgentOverride)(customUserAgentDetails);
        try {
            const basePromise = context
                ? client.graphql(context, {
                    ...auth,
                    query,
                    variables,
                }, headers)
                : client.graphql({
                    ...auth,
                    query,
                    variables,
                    ...userAgentOverride,
                }, headers);
            const extendedPromise = (0, cancellation_1.extendCancellability)(basePromise, resultPromise);
            const { data, extensions } = await extendedPromise;
            // flatten response
            if (data) {
                const [key] = Object.keys(data);
                const flattenedResult = (0, APIClient_1.flattenItems)(modelIntrospection, name, data[key]);
                if (flattenedResult === null) {
                    return { data: null, extensions };
                }
                else if (options?.selectionSet) {
                    return { data: flattenedResult, extensions };
                }
                else {
                    // TODO: refactor to avoid destructuring here
                    const [initialized] = (0, APIClient_1.initializeModel)(client, name, [flattenedResult], modelIntrospection, auth.authMode, auth.authToken, !!context);
                    return { data: initialized, extensions };
                }
            }
            else {
                return { data: null, extensions };
            }
        }
        catch (error) {
            /**
             * The `data` type returned by `error` here could be:
             * 1) `null`
             * 2) an empty object
             * 3) "populated" but with a `null` value `{ getPost: null }`
             * 4) an actual record `{ getPost: { id: '1', title: 'Hello, World!' } }`
             */
            const { data, errors } = error;
            /**
             * `data` is not `null`, and is not an empty object:
             */
            if (data && Object.keys(data).length !== 0 && errors) {
                const [key] = Object.keys(data);
                const flattenedResult = (0, APIClient_1.flattenItems)(modelIntrospection, name, data[key]);
                /**
                 * `flattenedResult` could be `null` here (e.g. `data: { getPost: null }`)
                 * if `flattenedResult`, result is an actual record:
                 */
                if (flattenedResult) {
                    if (options?.selectionSet) {
                        return { data: flattenedResult, errors };
                    }
                    else {
                        // TODO: refactor to avoid destructuring here
                        const [initialized] = (0, APIClient_1.initializeModel)(client, name, [flattenedResult], modelIntrospection, auth.authMode, auth.authToken, !!context);
                        return { data: initialized, errors };
                    }
                }
                else {
                    // was `data: { getPost: null }`)
                    return (0, utils_1.handleSingularGraphQlError)(error);
                }
            }
            else {
                // `data` is `null`:
                return (0, utils_1.handleSingularGraphQlError)(error);
            }
        }
    });
}
