import {
  generateClient,
  GraphQLQuery,
  GraphQLSubscription,
  SelectionSet,
  GraphQLAuthError,
  ConnectionState,
  GraphQLResult,
  GraphQLReturnType,
  CONNECTION_STATE_CHANGE,
  Client,
  ApiError,
} from 'aws-amplify/api'
import { Hub } from 'aws-amplify/utils'

import {
  SampleInsightsQuery,
  GetHealthQuery,
  OnInsightChangesSubscription,
  SampleInsightsQueryVariables,
  InsightQuery,
  InsightsQuery,
  ControlRulesQuery,
  ControlRulesQueryVariables,
  InsightsQueryVariables,
  InsightQueryVariables,
  OnInsightChangesSubscriptionVariables,
  SubmitInsightFeedbackMutationVariables,
  SubmitInsightFeedbackMutation,
  CreateInsightControlMutationVariables,
  CreateInsightControlMutation,
  Internal_upsertInsightMutationVariables,
  Internal_upsertInsightMutation,
  GetInsightRulesQuery,
  GetInsightRulesQueryVariables,
  AdminInsightSummaryQuery,
  AdminInsightSummaryQueryVariables,
  UserInsightSummaryQuery,
  UserInsightSummaryQueryVariables,
  CreateInsightMutation,
} from '../../graphQL/src/API'
import {
  CreateError,
  graphQLOperationAdditionalOptions,
  graphQLOperationOptions,
} from '../../utilities/utils'

import { CONSTANTS, ENV } from '../../constants/constants'

import { Amplify } from 'aws-amplify'
import { ICallbackType } from '../../interfaces/ICallback'

const client = generateClient()
export class AppSyncClient {
  public static Configure(env: ENV, key?: string): void {
    try {
      if (env === ENV.PRODUCTION) {
        Amplify.configure({
          API: {
            GraphQL: {
              endpoint: CONSTANTS.PRODUCTION_GRAPHQL_ENDPOINT,
              defaultAuthMode: 'lambda',
              apiKey: '', // Optional
              region: '', // Optional
              customEndpoint: '', // Optional
              customEndpointRegion: '', // Optional
            },
          },
        })
      } else {
        Amplify.configure({
          API: {
            GraphQL: {
              endpoint: CONSTANTS.STAGE_GRAPHQL_ENDPOINT,
              defaultAuthMode: 'lambda',
              apiKey: '', // Optional
              region: '', // Optional
              customEndpoint: '', // Optional
              customEndpointRegion: '', // Optional
            },
          },
        })
      }
    } catch (error) {
      throw CreateError('Error while configuring AppSync client', error)
    }
  }

  public static async RequestHealth(
    customQuery: GetHealthQuery,
    customVariables: any,
    token: string,
    user_id: string
  ): Promise<GraphQLResult<GetHealthQuery>> {
    try {
      return await client.graphql<GraphQLQuery<GetHealthQuery>>(
        graphQLOperationOptions(
          customQuery,
          customVariables,
          token,
          CONSTANTS.AUTH_MODE
        ),
        graphQLOperationAdditionalOptions(user_id)
      ) // Token will be treated as user
    } catch (error) {
      throw CreateError('Error while requesting health', error)
    }
  }

  public static async RequestInsight(
    customQuery: InsightQuery,
    customVariables: InsightQueryVariables,
    token: string,
    user_id: string
  ): Promise<GraphQLResult<InsightQuery>> {
    try {
      return await client.graphql<GraphQLQuery<InsightQuery>>(
        graphQLOperationOptions(
          customQuery,
          customVariables,
          token,
          CONSTANTS.AUTH_MODE
        ),
        graphQLOperationAdditionalOptions(user_id)
      ) // Token will be treated as user
    } catch (error) {
      throw CreateError('Error while requesting insight', error)
    }
  }

  public static async RequestInsights(
    customQuery: InsightsQuery,
    customVariables: InsightsQueryVariables,
    token: string,
    user_id: string
  ): Promise<GraphQLResult<InsightsQuery>> {
    try {
      return await client.graphql<GraphQLQuery<InsightsQuery>>(
        graphQLOperationOptions(
          customQuery,
          customVariables,
          token,
          CONSTANTS.AUTH_MODE
        ),
        graphQLOperationAdditionalOptions(user_id)
      ) // Token will be treated as user
    } catch (error) {
      throw CreateError('Error while requesting insights', error)
    }
  }

  public static async RequestSampleInsights(
    customQuery: SampleInsightsQuery,
    customVariables: SampleInsightsQueryVariables,
    token: string,
    user_id: string
  ): Promise<GraphQLResult<SampleInsightsQuery>> {
    try {
      return await client.graphql<GraphQLQuery<SampleInsightsQuery>>(
        graphQLOperationOptions(
          customQuery,
          customVariables,
          token,
          CONSTANTS.AUTH_MODE
        ),
        graphQLOperationAdditionalOptions(user_id)
      ) // Token will be treated as user
    } catch (error) {
      throw CreateError('Error while requesting sample insights', error)
    }
  }

  public static async RequestControlRules(
    customQuery: ControlRulesQuery,
    customVariables: ControlRulesQueryVariables,
    token: string,
    user_id: string
  ): Promise<GraphQLResult<ControlRulesQuery>> {
    try {
      return await client.graphql<GraphQLQuery<ControlRulesQuery>>(
        graphQLOperationOptions(
          customQuery,
          customVariables,
          token,
          CONSTANTS.AUTH_MODE
        ),
        graphQLOperationAdditionalOptions(user_id)
      ) // Token will be treated as user
    } catch (error) {
      throw CreateError('Error while requesting control rules', error)
    }
  }

  // WARNING: This function is not production ready
  // TODO: Implement unit tests and make production ready
  // public static SubscribeToInsights(
  //   customQuery: OnInsightChangesSubscription,
  //   customVariables: OnInsightChangesSubscriptionVariables,
  //   token: string,
  //   user_id: string,
  //   TriggerCallback: (type: ICallbackType, payload: any) => void
  // ) {
  //   try {
  //     Hub.listen('api', (data: any) => {
  //       const { payload } = data
  //       if (payload.event === CONNECTION_STATE_CHANGE) {
  //         const connectionState = payload.data
  //           .connectionState as ConnectionState
  //         console.log(connectionState)
  //       }
  //     })

  //     return client
  //       .graphql<
  //         GraphQLSubscription<OnInsightChangesSubscription>
  //       >(graphQLOperationOptions(customQuery, customVariables, token, CONSTANTS.AUTH_MODE), graphQLOperationAdditionalOptions(user_id))
  //       .subscribe({
  //         next: ({ data }) => {
  //           TriggerCallback(ICallbackType.SUBSCRIPTION, data)
  //         },
  //         error: (error) => {
  //           console.warn(error)

  //           // WIP : or TODO : if drops, start self healing ( maybe start polling? )
  //         },
  //       }) // Token will be treated as user
  //   } catch (error) {
  //     throw CreateError('Error while subscribing to insights', error)
  //   }
  // }

  public static async SubmitInsightFeedback(
    customQuery: SubmitInsightFeedbackMutation,
    customVariables: SubmitInsightFeedbackMutationVariables,
    token: string,
    user_id: string
  ): Promise<GraphQLResult<SubmitInsightFeedbackMutation>> {
    try {
      return await client.graphql<GraphQLQuery<SubmitInsightFeedbackMutation>>(
        graphQLOperationOptions(
          customQuery,
          customVariables,
          token,
          CONSTANTS.AUTH_MODE
        ),
        graphQLOperationAdditionalOptions(user_id)
      ) // Token will be treated as user
    } catch (error) {
      throw CreateError('Error while submitting insight feedback', error)
    }
  }

  public static async RequestCreateInsightControl(
    customQuery: CreateInsightControlMutation,
    customVariables: CreateInsightControlMutationVariables,
    token: string,
    user_id: string
  ): Promise<GraphQLResult<CreateInsightControlMutation>> {
    try {
      return await client.graphql<GraphQLQuery<CreateInsightControlMutation>>(
        graphQLOperationOptions(
          customQuery,
          customVariables,
          token,
          CONSTANTS.AUTH_MODE
        ),
        graphQLOperationAdditionalOptions(user_id)
      ) // Token will be treated as user
    } catch (error) {
      throw CreateError('Error while requesting create insight control', error)
    }
  }

  public static async RequestInsightRules(
    customQuery: GetInsightRulesQuery,
    customVariables: GetInsightRulesQueryVariables,
    token: string,
    user_id: string
  ): Promise<GraphQLResult<GetInsightRulesQuery>> {
    try {
      return await client.graphql<GraphQLQuery<GetInsightRulesQuery>>(
        graphQLOperationOptions(
          customQuery,
          customVariables,
          token,
          CONSTANTS.AUTH_MODE
        ),
        graphQLOperationAdditionalOptions(user_id)
      ) // Token will be treated as user
    } catch (error) {
      throw CreateError('Error while requesting insight', error)
    }
  }

  public static async RequestAdminInsightSummary(
    customQuery: AdminInsightSummaryQuery,
    customVariables: AdminInsightSummaryQueryVariables,
    token: string,
    user_id: string
  ): Promise<GraphQLResult<AdminInsightSummaryQuery>> {
    try {
      return await client.graphql<GraphQLQuery<AdminInsightSummaryQuery>>(
        graphQLOperationOptions(
          customQuery,
          customVariables,
          token,
          CONSTANTS.AUTH_MODE
        ),
        graphQLOperationAdditionalOptions(user_id)
      ) // Token will be treated as user
    } catch (error) {
      throw CreateError('Error while requesting admin insight summary', error)
    }
  }

  public static async RequestUserInsightSummary(
    customQuery: UserInsightSummaryQuery,
    customVariables: UserInsightSummaryQueryVariables,
    token: string,
    user_id: string
  ): Promise<GraphQLResult<UserInsightSummaryQuery>> {
    try {
      return await client.graphql<GraphQLQuery<UserInsightSummaryQuery>>(
        graphQLOperationOptions(
          customQuery,
          customVariables,
          token,
          CONSTANTS.AUTH_MODE
        ),
        graphQLOperationAdditionalOptions(user_id)
      ) // Token will be treated as user
    } catch (error) {
      throw CreateError('Error while requesting admin insight summary', error)
    }
  }

  public static async RequestCreateInsight(
    customQuery: CreateInsightMutation,
    customVariables: CreateInsightControlMutationVariables,
    token: string,
    user_id: string
  ): Promise<GraphQLResult<CreateInsightMutation>> {
    try {
      return await client.graphql<GraphQLQuery<CreateInsightMutation>>(
        graphQLOperationOptions(
          customQuery,
          customVariables,
          token,
          CONSTANTS.AUTH_MODE
        ),
        graphQLOperationAdditionalOptions(user_id)
      ) // Token will be treated as user
    } catch (error) {
      throw CreateError('Error while submitting insight feedback', error)
    }
  }

  // public static startPolling(toPoll, testFn, time) {
  //  // toPoll = toPoll || (clientInstance.GetInsights());
  //  let polling = false;
  //  let rejectThis = null;

  //  const stopPolling = () => {
  //    if (polling) {
  //      console.log(new Date(), 'Polling was already stopped...');
  //    } else {
  //      console.log(new Date(), 'Stopping polling...');
  //      polling = false;
  //      rejectThis(new Error('Polling cancelled'));
  //    }
  //  };

  //  const promise = new Promise((resolve, reject) => {
  //    polling = true;
  //    rejectThis = reject;

  //    const executePoll = async () => {
  //      try {
  //        const result = () => {};
  //        if (polling && testFn(result)) {
  //          polling = false;
  //          resolve(result);
  //        } else {
  //          setTimeout(executePoll, time);
  //        }
  //      } catch (error) {
  //        polling = false;
  //        reject(new Error('Polling cancelled due to API error'));
  //      }
  //    };

  //    setTimeout(executePoll, time);
  //  });

  //  return { promise, stopPolling };
  // }
}
