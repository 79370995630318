/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getHealth = /* GraphQL */ `query GetHealth {
  getHealth {
    status
    version
    metaVersion
    backendVersion
    commit
    region
    identity
    cfStack
    now
    __typename
  }
}
` as GeneratedQuery<APITypes.GetHealthQueryVariables, APITypes.GetHealthQuery>;
export const insights = /* GraphQL */ `query Insights(
  $documentId: String
  $contextId: String
  $productLineCode: String
  $categories: [String]
  $insightName: String
  $contentName: String
  $language: String
  $detailed: Boolean
  $clientType: String
  $clientVersion: String
  $markRead: Boolean
  $scope: String
  $showSamples: Boolean
  $showSnoozed: Boolean
  $showInternal: Boolean
  $pagination: PaginationInput
) {
  insights(
    documentId: $documentId
    contextId: $contextId
    productLineCode: $productLineCode
    categories: $categories
    insightName: $insightName
    contentName: $contentName
    language: $language
    detailed: $detailed
    clientType: $clientType
    clientVersion: $clientVersion
    markRead: $markRead
    scope: $scope
    showSamples: $showSamples
    showSnoozed: $showSnoozed
    showInternal: $showInternal
    pagination: $pagination
  ) {
    pagination {
      limit
      cursor
      __typename
    }
    insights {
      id
      name
      contextId
      userId
      documentId
      content {
        ... on ChartContent {
          type
          name
          options
          columns {
            id
            label
            type
            visualization {
              type
              options
              colors
              legends
              __typename
            }
            __typename
          }
          previewImageFallbackIcon
          previewImageUrl
          fragments
        }
        ... on MediaContent {
          videoUrl
          thumbnailUrl
          previewImageFallbackIcon
          previewImageUrl
          fragments
        }
        ... on WebContent {
          url
          embed
          dialogConfig {
            title
            timeout
            width
            height
            horizontalAlign
            verticalAlign
            triggerEvent
            initScript
            __typename
          }
          previewImageFallbackIcon
          previewImageUrl
          fragments
        }
      }
      scope
      createdTs
      lastUpdatedTs
      expirationTs
      title
      description
      productName
      productLineCode
      actions {
        title
        name
        parameters
        contentUrl
        __typename
      }
      summaryActions {
        title
        name
        parameters
        __typename
      }
      fragments
      userList
      visualization
      visualizationOptions {
        options
        colors
        __typename
      }
      visualizationData
      feedback {
        useful
        accurate
        showMore
        comment
        __typename
      }
      feedbackEnabled
      snoozeEnabled
      source
      sourceUuid
      payloadUrl
      categories
      chartData {
        data
        columns {
          id
          label
          type
          visualization {
            type
            options
            colors
            legends
            __typename
          }
          __typename
        }
        series
        donut_chart_data
        __typename
      }
      tealiumTag
      thumbnailUrl
      sample
      stringValues {
        name
        value
        value_type
        __typename
      }
      numericValues {
        name
        value
        value_type
        __typename
      }
      timeSeriesValues {
        name
        value
        __typename
      }
      jsonValues {
        name
        value
        value_type
        __typename
      }
      documentType
      documentSource
      contentName
      additionalPlcs
      additionalUsers {
        userId
        imageUrl
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.InsightsQueryVariables, APITypes.InsightsQuery>;
export const insight = /* GraphQL */ `query Insight(
  $insightId: String!
  $documentId: String
  $contextId: String
  $showInternal: Boolean
  $language: String
  $detailed: Boolean
  $clientVersion: String
) {
  insight(
    insightId: $insightId
    documentId: $documentId
    contextId: $contextId
    showInternal: $showInternal
    language: $language
    detailed: $detailed
    clientVersion: $clientVersion
  ) {
    id
    name
    contextId
    userId
    documentId
    content {
      ... on ChartContent {
        type
        name
        options
        columns {
          id
          label
          type
          visualization {
            type
            options
            colors
            legends
            __typename
          }
          __typename
        }
        previewImageFallbackIcon
        previewImageUrl
        fragments
      }
      ... on MediaContent {
        videoUrl
        thumbnailUrl
        previewImageFallbackIcon
        previewImageUrl
        fragments
      }
      ... on WebContent {
        url
        embed
        dialogConfig {
          title
          timeout
          width
          height
          horizontalAlign
          verticalAlign
          triggerEvent
          initScript
          __typename
        }
        previewImageFallbackIcon
        previewImageUrl
        fragments
      }
    }
    scope
    createdTs
    lastUpdatedTs
    expirationTs
    title
    description
    productName
    productLineCode
    actions {
      title
      name
      parameters
      contentUrl
      __typename
    }
    summaryActions {
      title
      name
      parameters
      __typename
    }
    fragments
    userList
    visualization
    visualizationOptions {
      options
      colors
      __typename
    }
    visualizationData
    feedback {
      useful
      accurate
      showMore
      comment
      __typename
    }
    feedbackEnabled
    snoozeEnabled
    source
    sourceUuid
    payloadUrl
    categories
    chartData {
      data
      columns {
        id
        label
        type
        visualization {
          type
          options
          colors
          legends
          __typename
        }
        __typename
      }
      series
      donut_chart_data
      __typename
    }
    tealiumTag
    thumbnailUrl
    sample
    stringValues {
      name
      value
      value_type
      __typename
    }
    numericValues {
      name
      value
      value_type
      __typename
    }
    timeSeriesValues {
      name
      value
      __typename
    }
    jsonValues {
      name
      value
      value_type
      __typename
    }
    documentType
    documentSource
    contentName
    additionalPlcs
    additionalUsers {
      userId
      imageUrl
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.InsightQueryVariables, APITypes.InsightQuery>;
export const controlRules = /* GraphQL */ `query ControlRules(
  $controlType: String
  $insightId: String
  $showExpired: Boolean
  $scope: String
) {
  controlRules(
    controlType: $controlType
    insightId: $insightId
    showExpired: $showExpired
    scope: $scope
  ) {
    items {
      id
      insightId
      contextId
      productLineCode
      controlType
      expirationTs
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ControlRulesQueryVariables,
  APITypes.ControlRulesQuery
>;
export const sampleInsights = /* GraphQL */ `query SampleInsights(
  $productLineCode: String
  $insightName: String
  $language: String
  $scope: String
  $detailed: Boolean
  $clientType: String
  $showSnoozed: Boolean
  $showInternal: Boolean
  $clientVersion: String
) {
  sampleInsights(
    productLineCode: $productLineCode
    insightName: $insightName
    language: $language
    scope: $scope
    detailed: $detailed
    clientType: $clientType
    showSnoozed: $showSnoozed
    showInternal: $showInternal
    clientVersion: $clientVersion
  ) {
    pagination {
      limit
      cursor
      __typename
    }
    insights {
      id
      name
      contextId
      userId
      documentId
      content {
        ... on ChartContent {
          type
          name
          options
          columns {
            id
            label
            type
            visualization {
              type
              options
              colors
              legends
              __typename
            }
            __typename
          }
          previewImageFallbackIcon
          previewImageUrl
          fragments
        }
        ... on MediaContent {
          videoUrl
          thumbnailUrl
          previewImageFallbackIcon
          previewImageUrl
          fragments
        }
        ... on WebContent {
          url
          embed
          dialogConfig {
            title
            timeout
            width
            height
            horizontalAlign
            verticalAlign
            triggerEvent
            initScript
            __typename
          }
          previewImageFallbackIcon
          previewImageUrl
          fragments
        }
      }
      scope
      createdTs
      lastUpdatedTs
      expirationTs
      title
      description
      productName
      productLineCode
      actions {
        title
        name
        parameters
        contentUrl
        __typename
      }
      summaryActions {
        title
        name
        parameters
        __typename
      }
      fragments
      userList
      visualization
      visualizationOptions {
        options
        colors
        __typename
      }
      visualizationData
      feedback {
        useful
        accurate
        showMore
        comment
        __typename
      }
      feedbackEnabled
      snoozeEnabled
      source
      sourceUuid
      payloadUrl
      categories
      chartData {
        data
        columns {
          id
          label
          type
          visualization {
            type
            options
            colors
            legends
            __typename
          }
          __typename
        }
        series
        donut_chart_data
        __typename
      }
      tealiumTag
      thumbnailUrl
      sample
      stringValues {
        name
        value
        value_type
        __typename
      }
      numericValues {
        name
        value
        value_type
        __typename
      }
      timeSeriesValues {
        name
        value
        __typename
      }
      jsonValues {
        name
        value
        value_type
        __typename
      }
      documentType
      documentSource
      contentName
      additionalPlcs
      additionalUsers {
        userId
        imageUrl
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SampleInsightsQueryVariables,
  APITypes.SampleInsightsQuery
>;
export const getInsightRules = /* GraphQL */ `query GetInsightRules($product: String!, $version: String) {
  getInsightRules(product: $product, version: $version) {
    productLineCode
    versionConstraint
    url
    checksum
    lastUpdatedTs
    rulesEngine
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInsightRulesQueryVariables,
  APITypes.GetInsightRulesQuery
>;
export const getInsightRulesMapping = /* GraphQL */ `query GetInsightRulesMapping($product: String!, $version: String) {
  getInsightRulesMapping(product: $product, version: $version) {
    productLineCode
    versionConstraint
    url
    checksum
    lastUpdatedTs
    rulesEngine
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInsightRulesMappingQueryVariables,
  APITypes.GetInsightRulesMappingQuery
>;
export const getInsightRulesEngines = /* GraphQL */ `query GetInsightRulesEngines(
  $engine: String!
  $latest: Boolean!
  $version: String
) {
  getInsightRulesEngines(engine: $engine, latest: $latest, version: $version) {
    engine
    version
    url
    checksum
    lastUpdatedTs
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInsightRulesEnginesQueryVariables,
  APITypes.GetInsightRulesEnginesQuery
>;
export const getLocalizedContent = /* GraphQL */ `query GetLocalizedContent($language: String!, $product: String!) {
  getLocalizedContent(language: $language, product: $product) {
    language
    product
    version
    checksum
    lastUpdatedTs
    url
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLocalizedContentQueryVariables,
  APITypes.GetLocalizedContentQuery
>;
export const adminInsightSummary = /* GraphQL */ `query AdminInsightSummary($contextId: String, $language: String) {
  adminInsightSummary(contextId: $contextId, language: $language) {
    insights {
      id
      name
      contextId
      userId
      documentId
      content {
        ... on ChartContent {
          type
          name
          options
          columns {
            id
            label
            type
            visualization {
              type
              options
              colors
              legends
              __typename
            }
            __typename
          }
          previewImageFallbackIcon
          previewImageUrl
          fragments
        }
        ... on MediaContent {
          videoUrl
          thumbnailUrl
          previewImageFallbackIcon
          previewImageUrl
          fragments
        }
        ... on WebContent {
          url
          embed
          dialogConfig {
            title
            timeout
            width
            height
            horizontalAlign
            verticalAlign
            triggerEvent
            initScript
            __typename
          }
          previewImageFallbackIcon
          previewImageUrl
          fragments
        }
      }
      scope
      createdTs
      lastUpdatedTs
      expirationTs
      title
      description
      productName
      productLineCode
      actions {
        title
        name
        parameters
        contentUrl
        __typename
      }
      summaryActions {
        title
        name
        parameters
        __typename
      }
      fragments
      userList
      visualization
      visualizationOptions {
        options
        colors
        __typename
      }
      visualizationData
      feedback {
        useful
        accurate
        showMore
        comment
        __typename
      }
      feedbackEnabled
      snoozeEnabled
      source
      sourceUuid
      payloadUrl
      categories
      chartData {
        data
        columns {
          id
          label
          type
          visualization {
            type
            options
            colors
            legends
            __typename
          }
          __typename
        }
        series
        donut_chart_data
        __typename
      }
      tealiumTag
      thumbnailUrl
      sample
      stringValues {
        name
        value
        value_type
        __typename
      }
      numericValues {
        name
        value
        value_type
        __typename
      }
      timeSeriesValues {
        name
        value
        __typename
      }
      jsonValues {
        name
        value
        value_type
        __typename
      }
      documentType
      documentSource
      contentName
      additionalPlcs
      additionalUsers {
        userId
        imageUrl
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminInsightSummaryQueryVariables,
  APITypes.AdminInsightSummaryQuery
>;
export const userInsightSummary = /* GraphQL */ `query UserInsightSummary($language: String) {
  userInsightSummary(language: $language) {
    insights {
      id
      name
      contextId
      userId
      documentId
      content {
        ... on ChartContent {
          type
          name
          options
          columns {
            id
            label
            type
            visualization {
              type
              options
              colors
              legends
              __typename
            }
            __typename
          }
          previewImageFallbackIcon
          previewImageUrl
          fragments
        }
        ... on MediaContent {
          videoUrl
          thumbnailUrl
          previewImageFallbackIcon
          previewImageUrl
          fragments
        }
        ... on WebContent {
          url
          embed
          dialogConfig {
            title
            timeout
            width
            height
            horizontalAlign
            verticalAlign
            triggerEvent
            initScript
            __typename
          }
          previewImageFallbackIcon
          previewImageUrl
          fragments
        }
      }
      scope
      createdTs
      lastUpdatedTs
      expirationTs
      title
      description
      productName
      productLineCode
      actions {
        title
        name
        parameters
        contentUrl
        __typename
      }
      summaryActions {
        title
        name
        parameters
        __typename
      }
      fragments
      userList
      visualization
      visualizationOptions {
        options
        colors
        __typename
      }
      visualizationData
      feedback {
        useful
        accurate
        showMore
        comment
        __typename
      }
      feedbackEnabled
      snoozeEnabled
      source
      sourceUuid
      payloadUrl
      categories
      chartData {
        data
        columns {
          id
          label
          type
          visualization {
            type
            options
            colors
            legends
            __typename
          }
          __typename
        }
        series
        donut_chart_data
        __typename
      }
      tealiumTag
      thumbnailUrl
      sample
      stringValues {
        name
        value
        value_type
        __typename
      }
      numericValues {
        name
        value
        value_type
        __typename
      }
      timeSeriesValues {
        name
        value
        __typename
      }
      jsonValues {
        name
        value
        value_type
        __typename
      }
      documentType
      documentSource
      contentName
      additionalPlcs
      additionalUsers {
        userId
        imageUrl
        __typename
      }
      __typename
    }
    products {
      productLineCode
      productName
      selected
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserInsightSummaryQueryVariables,
  APITypes.UserInsightSummaryQuery
>;
