/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onInsightChanges = /* GraphQL */ `subscription OnInsightChanges($filter: String) {
  onInsightChanges(filter: $filter) {
    id
    name
    contextId
    userId
    documentId
    content {
      ... on ChartContent {
        type
        name
        options
        columns {
          id
          label
          type
          visualization {
            type
            options
            colors
            legends
            __typename
          }
          __typename
        }
        previewImageFallbackIcon
        previewImageUrl
        fragments
      }
      ... on MediaContent {
        videoUrl
        thumbnailUrl
        previewImageFallbackIcon
        previewImageUrl
        fragments
      }
      ... on WebContent {
        url
        embed
        dialogConfig {
          title
          timeout
          width
          height
          horizontalAlign
          verticalAlign
          triggerEvent
          initScript
          __typename
        }
        previewImageFallbackIcon
        previewImageUrl
        fragments
      }
    }
    scope
    createdTs
    lastUpdatedTs
    expirationTs
    title
    description
    productName
    productLineCode
    actions {
      title
      name
      parameters
      contentUrl
      __typename
    }
    summaryActions {
      title
      name
      parameters
      __typename
    }
    fragments
    userList
    visualization
    visualizationOptions {
      options
      colors
      __typename
    }
    visualizationData
    feedback {
      useful
      accurate
      showMore
      comment
      __typename
    }
    feedbackEnabled
    snoozeEnabled
    source
    sourceUuid
    payloadUrl
    categories
    chartData {
      data
      columns {
        id
        label
        type
        visualization {
          type
          options
          colors
          legends
          __typename
        }
        __typename
      }
      series
      donut_chart_data
      __typename
    }
    tealiumTag
    thumbnailUrl
    sample
    stringValues {
      name
      value
      value_type
      __typename
    }
    numericValues {
      name
      value
      value_type
      __typename
    }
    timeSeriesValues {
      name
      value
      __typename
    }
    jsonValues {
      name
      value
      value_type
      __typename
    }
    documentType
    documentSource
    contentName
    additionalPlcs
    additionalUsers {
      userId
      imageUrl
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnInsightChangesSubscriptionVariables,
  APITypes.OnInsightChangesSubscription
>;
