"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateConversationsProperty = void 0;
const createCreateConversationFunction_1 = require("../../ai/createCreateConversationFunction");
const createGetConversationFunction_1 = require("../../ai/createGetConversationFunction");
const createListConversationsFunction_1 = require("../../ai/createListConversationsFunction");
const createDeleteConversationFunction_1 = require("../../ai/createDeleteConversationFunction");
const createUpdateConversationFunction_1 = require("../../ai/createUpdateConversationFunction");
function generateConversationsProperty(client, apiGraphQLConfig, getInternals) {
    const modelIntrospection = apiGraphQLConfig?.modelIntrospection;
    // conversations will be absent from model intro schema if no conversation routes
    // are present on the source schema.
    if (!modelIntrospection?.conversations) {
        return {};
    }
    const conversations = {};
    for (const { name, conversation, message, models, nonModels, enums, } of Object.values(modelIntrospection.conversations)) {
        const conversationModel = models[conversation.modelName];
        const conversationMessageModel = models[message.modelName];
        if (!conversationModel || !conversationMessageModel) {
            return {};
        }
        const conversationModelIntrospection = {
            ...modelIntrospection,
            models: {
                ...modelIntrospection.models,
                ...models,
            },
            nonModels: {
                ...modelIntrospection.nonModels,
                ...nonModels,
            },
            enums: {
                ...modelIntrospection.enums,
                ...enums,
            },
        };
        conversations[name] = {
            update: (0, createUpdateConversationFunction_1.createUpdateConversationFunction)(client, conversationModelIntrospection, name, conversationModel, conversationMessageModel, getInternals),
            create: (0, createCreateConversationFunction_1.createCreateConversationFunction)(client, conversationModelIntrospection, name, conversationModel, conversationMessageModel, getInternals),
            get: (0, createGetConversationFunction_1.createGetConversationFunction)(client, conversationModelIntrospection, name, conversationModel, conversationMessageModel, getInternals),
            delete: (0, createDeleteConversationFunction_1.createDeleteConversationFunction)(client, conversationModelIntrospection, name, conversationModel, conversationMessageModel, getInternals),
            list: (0, createListConversationsFunction_1.createListConversationsFunction)(client, conversationModelIntrospection, name, conversationModel, conversationMessageModel, getInternals),
        };
    }
    return conversations;
}
exports.generateConversationsProperty = generateConversationsProperty;
