// source: protos/insight.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.autodesk.spg.insights.Action', null, global);
goog.exportSymbol('proto.autodesk.spg.insights.Insight', null, global);
goog.exportSymbol('proto.autodesk.spg.insights.NumericValue', null, global);
goog.exportSymbol('proto.autodesk.spg.insights.StringValue', null, global);
goog.exportSymbol('proto.autodesk.spg.insights.TimeSeriesValue', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.autodesk.spg.insights.StringValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.autodesk.spg.insights.StringValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.autodesk.spg.insights.StringValue.displayName = 'proto.autodesk.spg.insights.StringValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.autodesk.spg.insights.NumericValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.autodesk.spg.insights.NumericValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.autodesk.spg.insights.NumericValue.displayName = 'proto.autodesk.spg.insights.NumericValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.autodesk.spg.insights.TimeSeriesValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.autodesk.spg.insights.TimeSeriesValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.autodesk.spg.insights.TimeSeriesValue.displayName = 'proto.autodesk.spg.insights.TimeSeriesValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.autodesk.spg.insights.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.autodesk.spg.insights.Action.repeatedFields_, null);
};
goog.inherits(proto.autodesk.spg.insights.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.autodesk.spg.insights.Action.displayName = 'proto.autodesk.spg.insights.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.autodesk.spg.insights.Insight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.autodesk.spg.insights.Insight.repeatedFields_, null);
};
goog.inherits(proto.autodesk.spg.insights.Insight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.autodesk.spg.insights.Insight.displayName = 'proto.autodesk.spg.insights.Insight';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.autodesk.spg.insights.StringValue.prototype.toObject = function(opt_includeInstance) {
  return proto.autodesk.spg.insights.StringValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.autodesk.spg.insights.StringValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.spg.insights.StringValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    valueType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.autodesk.spg.insights.StringValue}
 */
proto.autodesk.spg.insights.StringValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.autodesk.spg.insights.StringValue;
  return proto.autodesk.spg.insights.StringValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.autodesk.spg.insights.StringValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.autodesk.spg.insights.StringValue}
 */
proto.autodesk.spg.insights.StringValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.autodesk.spg.insights.StringValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.autodesk.spg.insights.StringValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.autodesk.spg.insights.StringValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.spg.insights.StringValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValueType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.autodesk.spg.insights.StringValue.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.StringValue} returns this
 */
proto.autodesk.spg.insights.StringValue.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.autodesk.spg.insights.StringValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.StringValue} returns this
 */
proto.autodesk.spg.insights.StringValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value_type = 3;
 * @return {string}
 */
proto.autodesk.spg.insights.StringValue.prototype.getValueType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.StringValue} returns this
 */
proto.autodesk.spg.insights.StringValue.prototype.setValueType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.autodesk.spg.insights.NumericValue.prototype.toObject = function(opt_includeInstance) {
  return proto.autodesk.spg.insights.NumericValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.autodesk.spg.insights.NumericValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.spg.insights.NumericValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    valueType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.autodesk.spg.insights.NumericValue}
 */
proto.autodesk.spg.insights.NumericValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.autodesk.spg.insights.NumericValue;
  return proto.autodesk.spg.insights.NumericValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.autodesk.spg.insights.NumericValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.autodesk.spg.insights.NumericValue}
 */
proto.autodesk.spg.insights.NumericValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.autodesk.spg.insights.NumericValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.autodesk.spg.insights.NumericValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.autodesk.spg.insights.NumericValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.spg.insights.NumericValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getValueType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.autodesk.spg.insights.NumericValue.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.NumericValue} returns this
 */
proto.autodesk.spg.insights.NumericValue.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float value = 2;
 * @return {number}
 */
proto.autodesk.spg.insights.NumericValue.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.autodesk.spg.insights.NumericValue} returns this
 */
proto.autodesk.spg.insights.NumericValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string value_type = 3;
 * @return {string}
 */
proto.autodesk.spg.insights.NumericValue.prototype.getValueType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.NumericValue} returns this
 */
proto.autodesk.spg.insights.NumericValue.prototype.setValueType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.autodesk.spg.insights.TimeSeriesValue.prototype.toObject = function(opt_includeInstance) {
  return proto.autodesk.spg.insights.TimeSeriesValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.autodesk.spg.insights.TimeSeriesValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.spg.insights.TimeSeriesValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.autodesk.spg.insights.TimeSeriesValue}
 */
proto.autodesk.spg.insights.TimeSeriesValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.autodesk.spg.insights.TimeSeriesValue;
  return proto.autodesk.spg.insights.TimeSeriesValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.autodesk.spg.insights.TimeSeriesValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.autodesk.spg.insights.TimeSeriesValue}
 */
proto.autodesk.spg.insights.TimeSeriesValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.autodesk.spg.insights.TimeSeriesValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.autodesk.spg.insights.TimeSeriesValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.autodesk.spg.insights.TimeSeriesValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.spg.insights.TimeSeriesValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.autodesk.spg.insights.TimeSeriesValue.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.TimeSeriesValue} returns this
 */
proto.autodesk.spg.insights.TimeSeriesValue.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float value = 2;
 * @return {number}
 */
proto.autodesk.spg.insights.TimeSeriesValue.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.autodesk.spg.insights.TimeSeriesValue} returns this
 */
proto.autodesk.spg.insights.TimeSeriesValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.autodesk.spg.insights.TimeSeriesValue.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.autodesk.spg.insights.TimeSeriesValue} returns this
*/
proto.autodesk.spg.insights.TimeSeriesValue.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.autodesk.spg.insights.TimeSeriesValue} returns this
 */
proto.autodesk.spg.insights.TimeSeriesValue.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.autodesk.spg.insights.TimeSeriesValue.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.autodesk.spg.insights.Action.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.autodesk.spg.insights.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.autodesk.spg.insights.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.autodesk.spg.insights.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.spg.insights.Action.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    parametersList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.autodesk.spg.insights.Action}
 */
proto.autodesk.spg.insights.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.autodesk.spg.insights.Action;
  return proto.autodesk.spg.insights.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.autodesk.spg.insights.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.autodesk.spg.insights.Action}
 */
proto.autodesk.spg.insights.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.autodesk.spg.insights.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.autodesk.spg.insights.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.autodesk.spg.insights.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.spg.insights.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParametersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.autodesk.spg.insights.Action.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.Action} returns this
 */
proto.autodesk.spg.insights.Action.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string parameters = 2;
 * @return {!Array<string>}
 */
proto.autodesk.spg.insights.Action.prototype.getParametersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.autodesk.spg.insights.Action} returns this
 */
proto.autodesk.spg.insights.Action.prototype.setParametersList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.autodesk.spg.insights.Action} returns this
 */
proto.autodesk.spg.insights.Action.prototype.addParameters = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.autodesk.spg.insights.Action} returns this
 */
proto.autodesk.spg.insights.Action.prototype.clearParametersList = function() {
  return this.setParametersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.autodesk.spg.insights.Insight.repeatedFields_ = [13,14,15,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.autodesk.spg.insights.Insight.prototype.toObject = function(opt_includeInstance) {
  return proto.autodesk.spg.insights.Insight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.autodesk.spg.insights.Insight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.spg.insights.Insight.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    source: jspb.Message.getFieldWithDefault(msg, 3, ""),
    expirationTs: (f = msg.getExpirationTs()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    generationTs: (f = msg.getGenerationTs()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    contextId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    adminO2Id: jspb.Message.getFieldWithDefault(msg, 6, ""),
    documentId: jspb.Message.getFieldWithDefault(msg, 19, ""),
    productLineCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    endUserO2Id: jspb.Message.getFieldWithDefault(msg, 8, ""),
    documentSource: jspb.Message.getFieldWithDefault(msg, 20, ""),
    documentType: jspb.Message.getFieldWithDefault(msg, 21, ""),
    contentName: jspb.Message.getFieldWithDefault(msg, 22, ""),
    confidence: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    version: jspb.Message.getFieldWithDefault(msg, 10, ""),
    isPublic: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    priority: jspb.Message.getFieldWithDefault(msg, 12, 0),
    stringValuesList: jspb.Message.toObjectList(msg.getStringValuesList(),
    proto.autodesk.spg.insights.StringValue.toObject, includeInstance),
    numericValuesList: jspb.Message.toObjectList(msg.getNumericValuesList(),
    proto.autodesk.spg.insights.NumericValue.toObject, includeInstance),
    timeSeriesValuesList: jspb.Message.toObjectList(msg.getTimeSeriesValuesList(),
    proto.autodesk.spg.insights.TimeSeriesValue.toObject, includeInstance),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.autodesk.spg.insights.Action.toObject, includeInstance),
    metaVersion: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.autodesk.spg.insights.Insight}
 */
proto.autodesk.spg.insights.Insight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.autodesk.spg.insights.Insight;
  return proto.autodesk.spg.insights.Insight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.autodesk.spg.insights.Insight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.autodesk.spg.insights.Insight}
 */
proto.autodesk.spg.insights.Insight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationTs(value);
      break;
    case 18:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setGenerationTs(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContextId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminO2Id(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductLineCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndUserO2Id(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentSource(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentType(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentName(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConfidence(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPublic(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriority(value);
      break;
    case 13:
      var value = new proto.autodesk.spg.insights.StringValue;
      reader.readMessage(value,proto.autodesk.spg.insights.StringValue.deserializeBinaryFromReader);
      msg.addStringValues(value);
      break;
    case 14:
      var value = new proto.autodesk.spg.insights.NumericValue;
      reader.readMessage(value,proto.autodesk.spg.insights.NumericValue.deserializeBinaryFromReader);
      msg.addNumericValues(value);
      break;
    case 15:
      var value = new proto.autodesk.spg.insights.TimeSeriesValue;
      reader.readMessage(value,proto.autodesk.spg.insights.TimeSeriesValue.deserializeBinaryFromReader);
      msg.addTimeSeriesValues(value);
      break;
    case 16:
      var value = new proto.autodesk.spg.insights.Action;
      reader.readMessage(value,proto.autodesk.spg.insights.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetaVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.autodesk.spg.insights.Insight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.autodesk.spg.insights.Insight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.autodesk.spg.insights.Insight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.autodesk.spg.insights.Insight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExpirationTs();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGenerationTs();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getContextId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAdminO2Id();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getProductLineCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEndUserO2Id();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDocumentSource();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getDocumentType();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getContentName();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getConfidence();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIsPublic();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getStringValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.autodesk.spg.insights.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumericValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.autodesk.spg.insights.NumericValue.serializeBinaryToWriter
    );
  }
  f = message.getTimeSeriesValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.autodesk.spg.insights.TimeSeriesValue.serializeBinaryToWriter
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.autodesk.spg.insights.Action.serializeBinaryToWriter
    );
  }
  f = message.getMetaVersion();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.autodesk.spg.insights.Insight.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.autodesk.spg.insights.Insight.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string source = 3;
 * @return {string}
 */
proto.autodesk.spg.insights.Insight.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp expiration_ts = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.autodesk.spg.insights.Insight.prototype.getExpirationTs = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
*/
proto.autodesk.spg.insights.Insight.prototype.setExpirationTs = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.clearExpirationTs = function() {
  return this.setExpirationTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.autodesk.spg.insights.Insight.prototype.hasExpirationTs = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp generation_ts = 18;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.autodesk.spg.insights.Insight.prototype.getGenerationTs = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 18));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
*/
proto.autodesk.spg.insights.Insight.prototype.setGenerationTs = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.clearGenerationTs = function() {
  return this.setGenerationTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.autodesk.spg.insights.Insight.prototype.hasGenerationTs = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string context_id = 5;
 * @return {string}
 */
proto.autodesk.spg.insights.Insight.prototype.getContextId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.setContextId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string admin_o2_id = 6;
 * @return {string}
 */
proto.autodesk.spg.insights.Insight.prototype.getAdminO2Id = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.setAdminO2Id = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string document_id = 19;
 * @return {string}
 */
proto.autodesk.spg.insights.Insight.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string product_line_code = 7;
 * @return {string}
 */
proto.autodesk.spg.insights.Insight.prototype.getProductLineCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.setProductLineCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string end_user_o2_id = 8;
 * @return {string}
 */
proto.autodesk.spg.insights.Insight.prototype.getEndUserO2Id = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.setEndUserO2Id = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string document_source = 20;
 * @return {string}
 */
proto.autodesk.spg.insights.Insight.prototype.getDocumentSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.setDocumentSource = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string document_type = 21;
 * @return {string}
 */
proto.autodesk.spg.insights.Insight.prototype.getDocumentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.setDocumentType = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string content_name = 22;
 * @return {string}
 */
proto.autodesk.spg.insights.Insight.prototype.getContentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.setContentName = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional double confidence = 9;
 * @return {number}
 */
proto.autodesk.spg.insights.Insight.prototype.getConfidence = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.setConfidence = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string version = 10;
 * @return {string}
 */
proto.autodesk.spg.insights.Insight.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool is_public = 11;
 * @return {boolean}
 */
proto.autodesk.spg.insights.Insight.prototype.getIsPublic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.setIsPublic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 priority = 12;
 * @return {number}
 */
proto.autodesk.spg.insights.Insight.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated StringValue string_values = 13;
 * @return {!Array<!proto.autodesk.spg.insights.StringValue>}
 */
proto.autodesk.spg.insights.Insight.prototype.getStringValuesList = function() {
  return /** @type{!Array<!proto.autodesk.spg.insights.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.autodesk.spg.insights.StringValue, 13));
};


/**
 * @param {!Array<!proto.autodesk.spg.insights.StringValue>} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
*/
proto.autodesk.spg.insights.Insight.prototype.setStringValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.autodesk.spg.insights.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.autodesk.spg.insights.StringValue}
 */
proto.autodesk.spg.insights.Insight.prototype.addStringValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.autodesk.spg.insights.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.clearStringValuesList = function() {
  return this.setStringValuesList([]);
};


/**
 * repeated NumericValue numeric_values = 14;
 * @return {!Array<!proto.autodesk.spg.insights.NumericValue>}
 */
proto.autodesk.spg.insights.Insight.prototype.getNumericValuesList = function() {
  return /** @type{!Array<!proto.autodesk.spg.insights.NumericValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.autodesk.spg.insights.NumericValue, 14));
};


/**
 * @param {!Array<!proto.autodesk.spg.insights.NumericValue>} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
*/
proto.autodesk.spg.insights.Insight.prototype.setNumericValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.autodesk.spg.insights.NumericValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.autodesk.spg.insights.NumericValue}
 */
proto.autodesk.spg.insights.Insight.prototype.addNumericValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.autodesk.spg.insights.NumericValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.clearNumericValuesList = function() {
  return this.setNumericValuesList([]);
};


/**
 * repeated TimeSeriesValue time_series_values = 15;
 * @return {!Array<!proto.autodesk.spg.insights.TimeSeriesValue>}
 */
proto.autodesk.spg.insights.Insight.prototype.getTimeSeriesValuesList = function() {
  return /** @type{!Array<!proto.autodesk.spg.insights.TimeSeriesValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.autodesk.spg.insights.TimeSeriesValue, 15));
};


/**
 * @param {!Array<!proto.autodesk.spg.insights.TimeSeriesValue>} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
*/
proto.autodesk.spg.insights.Insight.prototype.setTimeSeriesValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.autodesk.spg.insights.TimeSeriesValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.autodesk.spg.insights.TimeSeriesValue}
 */
proto.autodesk.spg.insights.Insight.prototype.addTimeSeriesValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.autodesk.spg.insights.TimeSeriesValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.clearTimeSeriesValuesList = function() {
  return this.setTimeSeriesValuesList([]);
};


/**
 * repeated Action actions = 16;
 * @return {!Array<!proto.autodesk.spg.insights.Action>}
 */
proto.autodesk.spg.insights.Insight.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.autodesk.spg.insights.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.autodesk.spg.insights.Action, 16));
};


/**
 * @param {!Array<!proto.autodesk.spg.insights.Action>} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
*/
proto.autodesk.spg.insights.Insight.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.autodesk.spg.insights.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.autodesk.spg.insights.Action}
 */
proto.autodesk.spg.insights.Insight.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.autodesk.spg.insights.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional string meta_version = 17;
 * @return {string}
 */
proto.autodesk.spg.insights.Insight.prototype.getMetaVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.autodesk.spg.insights.Insight} returns this
 */
proto.autodesk.spg.insights.Insight.prototype.setMetaVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


goog.object.extend(exports, proto.autodesk.spg.insights);
