"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateModelsProperty = void 0;
const list_1 = require("../operations/list");
const indexQuery_1 = require("../operations/indexQuery");
const get_1 = require("../operations/get");
const clientUtils_1 = require("../clientUtils");
function generateModelsProperty(client, params, getInternals) {
    const models = {};
    const { config } = params;
    const useContext = params.amplify === null;
    if (!config) {
        throw new Error('generateModelsProperty cannot retrieve Amplify config');
    }
    if (!config.API?.GraphQL) {
        return {};
    }
    const modelIntrospection = config.API.GraphQL.modelIntrospection;
    if (!modelIntrospection) {
        return {};
    }
    const SSR_UNSUPORTED_OPS = [
        'ONCREATE',
        'ONUPDATE',
        'ONDELETE',
        'OBSERVEQUERY',
    ];
    for (const model of Object.values(modelIntrospection.models)) {
        const { name } = model;
        models[name] = {};
        const enabledModelOps = (0, clientUtils_1.excludeDisabledOps)(modelIntrospection, name);
        Object.entries(enabledModelOps).forEach(([key, { operationPrefix }]) => {
            const operation = key;
            // subscriptions are not supported in SSR
            if (SSR_UNSUPORTED_OPS.includes(operation))
                return;
            if (operation === 'LIST') {
                models[name][operationPrefix] = (0, list_1.listFactory)(client, modelIntrospection, model, getInternals, useContext);
            }
            else {
                models[name][operationPrefix] = (0, get_1.getFactory)(client, modelIntrospection, model, operation, getInternals, useContext);
            }
        });
        const secondaryIdxs = (0, clientUtils_1.getSecondaryIndexesFromSchemaModel)(model);
        for (const idx of secondaryIdxs) {
            models[name][idx.queryField] = (0, indexQuery_1.indexQueryFactory)(client, modelIntrospection, model, idx, getInternals, useContext);
        }
    }
    return models;
}
exports.generateModelsProperty = generateModelsProperty;
