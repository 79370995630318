"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTokenExpired = void 0;
function isTokenExpired({ expiresAt, clockDrift, tolerance = 5000, }) {
    const currentTime = Date.now();
    return currentTime + clockDrift + tolerance > expiresAt;
}
exports.isTokenExpired = isTokenExpired;
