'use strict'
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AppSyncClient } from './clients/appsync'
import { RestClient } from './clients/rest'

import { ENV } from './constants/constants'

import { ICallback, ICallbackType } from './interfaces/ICallback'

import { IHostIntegrationHandler } from './interfaces/IHostIntegrationHandler'
import {
  decodeJSONFields,
  isTokenExpired,
  getOxygenID,
} from './utilities/utils'

import { Insight as InsightData } from './generatedProtoBindings/protos/insight_pb'

import { GatewayClient } from './clients/gateway'
import { LevelFilter } from './generatedProtoBindings/protos/grpc_insight_pb'
import { IState } from './interfaces/IState'
import { Insight } from './exports'

export * from './exports'

export let instance: InsightsClient | null = null

export default class InsightsClient {
  integrationHandler!: IHostIntegrationHandler
  env!: ENV
  token!: string | null
  state!: IState
  constructor(integrationHandler: IHostIntegrationHandler, env: ENV) {
    if (instance) {
      throw new Error('New instance cannot be created!!')
    }
    instance = this
    instance.integrationHandler = integrationHandler
    instance.env = env
    instance.token = null
    instance.state = {
      appSyncStatus: {
        url: null,
        region: null,
        isConfigured: false,
      },
      restStatus: {
        url: null,
        isConfigured: false,
      },
      gRPCStatus: {
        isConfigured: false,
        isServerAvailable: false,
        shouldConfigure: instance.integrationHandler.is_IDC_required
          ? true
          : false,
      },
    }
  }

  TriggerCallback(type: ICallbackType, payload: IState | Array<Insight>) {
    const cb: ICallback = {
      callbackType: type,
      payload: payload,
    }
    this.integrationHandler.callback(JSON.stringify(cb))
  }
  /**
   * The function performs checks on a token, including checking if the InsightsClient is initialized,
   * if the token starts with 'Bearer ', and if the token is expired.
   * @param {string} token - The `token` parameter is a string that represents an authentication token.
   */
  PerformChecks(token: string) {
    if (instance === null) {
      this.RecordLog(
        'PerformChecks',
        'InsightsClient is not initialized!!',
        LevelFilter.ERROR
      )
      throw new Error('InsightsClient is not initialized!!')
    }
    if (!token.startsWith('Bearer ')) {
      this.RecordLog(
        'PerformChecks',
        'Token with Bearer prefix required',
        LevelFilter.ERROR
      )
      throw new Error('Token with Bearer prefix required')
    }
    try {
      isTokenExpired(token)
    } catch (error) {
      this.RecordLog('PerformChecks', JSON.stringify(error), LevelFilter.ERROR)
      throw error
    }
  }

  /**
   * The InitializeInsightsClient function initializes the Insights client by performing checks,
   * configuring the AppSync, Rest, and gRPC clients, and returning a success message.
   * @returns an object with two properties: "status" and "message". The "status" property is set to
   * the string value "success" and the "message" property is set to the string value "Insights client
   * initialized successfully".
   */
  async InitializeInsightsClient() {
    const token = await this.integrationHandler.getAccessToken()

    this.PerformChecks(token)
    // Configure the AppSync client
    AppSyncClient.Configure(this.env)
    this.state.appSyncStatus.isConfigured = true
    // Configure the Rest client
    RestClient.Configure(this.env)
    this.state.restStatus.isConfigured = true

    // Configure the gRPC client
    this.state.gRPCStatus.isServerAvailable = false
    if (this.state.gRPCStatus.shouldConfigure) {
      GatewayClient.InitGatewayClientConfigForeverLoop(this)
      this.state.gRPCStatus.isConfigured = true
    }
    this.TriggerCallback(ICallbackType.STATUS, this.state)
    return {
      status: this.state,
      message: 'Insights client initialized successfully',
    }
  }

  DestroyInstance() {
    GatewayClient.ClearForeverLoop()
    instance = null
    return instance
  }

  // AppSync API exports

  /**
   *
   * @param query - is a graphQL query to fetch health of the AppSync API
   *
   * @param filter - can be specified to curate the response according to the data needed
   *
   * @returns - a promise that resolves to a GraphQLResult object
   */
  async GetHealth(query: any, filter: any) {
    const token = await this.integrationHandler.getAccessToken()
    this.PerformChecks(token)

    this.RecordLog(
      'GetHealth',
      JSON.stringify({ query: query, filter: filter }),
      LevelFilter.INFO
    )
    return await AppSyncClient.RequestHealth(
      query,
      filter,
      token,
      getOxygenID(token)
    )
  }

  /**
   *
   * @param query - is a graphQL query to fetch sample insights
   *
   * @param filter - can be specified to curate the response according to the data needed. The filter has to be a type of SampleInsightsQueryVariables
   *
   * @returns - a promise that resolves to a GraphQLResult object
   */
  async GetSampleInsights(query: any, filter: any) {
    const token = await this.integrationHandler.getAccessToken()
    this.PerformChecks(token)
    this.RecordLog(
      'GetSampleInsights',
      JSON.stringify({ query: query, filter: filter }),
      LevelFilter.INFO
    )
    const out = await AppSyncClient.RequestSampleInsights(
      query,
      filter,
      token,
      getOxygenID(token)
    )
    if (
      out &&
      out.data &&
      out.data.sampleInsights &&
      out.data.sampleInsights.insights
    ) {
      decodeJSONFields(out.data.sampleInsights.insights, false)
    }
    return out
  }

  /**
   *
   * @param query - is a graphQL query to fetch insight using ID
   *
   * @param filter - can be specified to curate the response according to the data needed. The filter has to be a type of InsightQueryVariables
   *
   * @returns - a promise that resolves to a GraphQLResult object
   */
  async GetInsight(query: any, filter: any) {
    const token = await this.integrationHandler.getAccessToken()
    this.PerformChecks(token)
    this.RecordLog(
      'GetInsight',
      JSON.stringify({ query: query, filter: filter }),
      LevelFilter.INFO
    )
    const out = await AppSyncClient.RequestInsight(
      query,
      filter,
      token,
      getOxygenID(token)
    )
    if (out && out.data && out.data.insight) {
      decodeJSONFields(out.data.insight, false)
    }
    return out
  }

  /**
   *
   * @param query - is a graphQL query to fetch insights related to the user
   *
   * @param filter - can be specified to curate the response according to the data needed. The filter has to be a type of InsightsQueryVariables
   *
   * @returns - a promise that resolves to a GraphQLResult object
   */
  async GetInsights(query: any, filter: any) {
    const token = await this.integrationHandler.getAccessToken()
    this.PerformChecks(token)
    this.RecordLog(
      'GetInsight',
      JSON.stringify({ query: query, filter: filter }),
      LevelFilter.INFO
    )
    const out = await AppSyncClient.RequestInsights(
      query,
      filter,
      token,
      getOxygenID(token)
    )
    if (out && out.data && out.data.insights && out.data.insights.insights) {
      decodeJSONFields(out.data.insights.insights, false)
    }
    console.log(out)
    return out
  }

  /**
   *
   * @param query - is a graphQL query to fetch control rules
   *
   * @param filter - can be specified to curate the response according to the data needed. The filter has to be a type of ControlRulesQueryVariables
   *
   * @returns - a promise that resolves to a GraphQLResult object
   */
  async GetControlRules(query: any, filter: any) {
    const token = await this.integrationHandler.getAccessToken()
    this.PerformChecks(token)
    this.RecordLog(
      'GetControlRules',
      JSON.stringify({ query: query, filter: filter }),
      LevelFilter.INFO
    )
    return await AppSyncClient.RequestControlRules(
      query,
      filter,
      token,
      getOxygenID(token)
    )
  }

  /**
   *
   * @param query - a graphQL query to control what fields from ControlRule are returned
   *
   * @param data - Object of type CreateInsightControlMutationVariables which contains the control rule data
   *
   * @returns - a promise that resolves to a GraphQLResult object
   */
  async CreateControlRule(query: any, data: any) {
    const token = await this.integrationHandler.getAccessToken()
    this.PerformChecks(token)
    this.RecordLog(
      'CreateControlRule',
      JSON.stringify({ query: query, data: data }),
      LevelFilter.INFO
    )
    return await AppSyncClient.RequestCreateInsightControl(
      query,
      data,
      token,
      getOxygenID(token)
    )
  }

  /**
   *
   * @param query - is a graphQL query to submit insight feedback
   *
   * @param data - Object of type SubmitInsightFeedbackMutationVariables which contains the feedback data
   *
   * @returns - a promise that resolves to a GraphQLResult object
   */
  async SubmitInsightsFeedback(query: any, data: any) {
    const token = await this.integrationHandler.getAccessToken()
    this.PerformChecks(token)
    this.RecordLog(
      'SubmitInsightsFeedback',
      JSON.stringify({ query: query, data: data }),
      LevelFilter.INFO
    )
    return await AppSyncClient.SubmitInsightFeedback(
      query,
      data,
      token,
      getOxygenID(token)
    )
  }

  /**
   *
   * @param query - is a graphQL subscription query to subscribe to the insights
   *
   * @param filter - can be specified to curate the response according to the data needed
   *
   * @param token - is Bearer token if authMode is AWS_LAMBDA. In development mode the userID will be extracted and used as 'x-user-id'
   *
   * @param callback - is required. This will be called whenever there is a new insight
   *
   * @constructor
   */

  // WARNING: This function is not production ready
  // TODO: Implement unit tests and make production ready
  // async InsightsSubscription(query: any, filter: any) {
  //   const token = await this.integrationHandler.getAccessToken()
  //   this.PerformChecks(token)
  //   this.RecordLog(
  //     'InsightsSubscription',
  //     JSON.stringify({ query: query, filter: filter }),
  //     LevelFilter.INFO
  //   )
  //   const subscriptionHandle = await AppSyncClient.SubscribeToInsights(
  //     query,
  //     filter,
  //     token,
  //     getOxygenID(token),
  //     this.integrationHandler.callback
  //   )
  //   console.log(
  //     `Subscribed to insights subscription with user` + getOxygenID(token)
  //   )
  //   return subscriptionHandle
  // }

  /**

   * @param query - is a graphQL query to fetch insight rules
   * @param filter - can be specified to curate the response according to the data needed. The filter has to be a type of InsightRulesQueryVariables
   * @constructor - a promise that resolves to a GraphQLResult object
   */
  async GetInsightsRule(query: any, filter: any) {
    const token = await this.integrationHandler.getAccessToken()
    this.PerformChecks(token)
    this.RecordLog(
      'GetInsightRule',
      JSON.stringify({ query: query, filter: filter }),
      LevelFilter.INFO
    )
    const out = await AppSyncClient.RequestInsightRules(
      query,
      filter,
      token,
      getOxygenID(token)
    )
    return out
  }

  /**
   *
   * @param query - is a graphQL query to fetch admin insights summary
   *
   * @param filter - can be specified to curate the response according to the data needed. The filter has to be a type of AdminInsightSummaryQueryVariables
   *
   * @returns - a promise that resolves to a GraphQLResult object
   */
  async GetAdminInsightSummary(query: any, filter: any) {
    const token = await this.integrationHandler.getAccessToken()
    this.PerformChecks(token)
    this.RecordLog(
      'GetAdminInsightSummary',
      JSON.stringify({ query: query, filter: filter }),
      LevelFilter.INFO
    )
    const out = await AppSyncClient.RequestAdminInsightSummary(
      query,
      filter,
      token,
      getOxygenID(token)
    )
    if (out?.data?.adminInsightSummary?.insights) {
      decodeJSONFields(out.data.adminInsightSummary.insights, false)
    }
    console.log(out)
    return out
  }

  /**
   *
   * @param query - is a graphQL query to fetch user insights summary
   *
   * @param filter - can be specified to curate the response according to the data needed. The filter has to be a type of UserInsightSummaryQueryVariables
   *
   * @returns - a promise that resolves to a GraphQLResult object
   */
  async GetUserInsightSummary(query: any, filter: any) {
    const token = await this.integrationHandler.getAccessToken()
    this.PerformChecks(token)
    this.RecordLog(
      'GetUserInsightSummary',
      JSON.stringify({ query: query, filter: filter }),
      LevelFilter.INFO
    )
    const out = await AppSyncClient.RequestUserInsightSummary(
      query,
      filter,
      token,
      getOxygenID(token)
    )
    if (out?.data?.userInsightSummary?.insights) {
      decodeJSONFields(out.data.userInsightSummary.insights, false)
    }
    console.log(out)
    return out
  }

  /**
   *
   * @param query - is a graphQL mutation query to CreateInsight
   *
   * @param filter - can be specified to curate the response according to the data needed. The filter has to be a type of CreateInsightControlMutationVariables
   *
   * @returns - a promise that resolves to a GraphQLResult object
   */
  async CreateInsight(query: any, filter: any) {
    const token = await this.integrationHandler.getAccessToken()
    this.PerformChecks(token)
    this.RecordLog(
      'RequestCreateInsight',
      JSON.stringify({ query: query, filter: filter }),
      LevelFilter.INFO
    )
    const out = await AppSyncClient.RequestCreateInsight(
      query,
      filter,
      token,
      getOxygenID(token)
    )
    if (
      out &&
      out.data &&
      out.data.createInsight &&
      out.data.createInsight.id
    ) {
      decodeJSONFields(out.data.createInsight.id, false)
    }
    console.log(out)
    return out
  }

  // REST API exports

  /**
   *
   * @param insight - is an object of type InsightData which contains the insight data
   *
   * @returns - a promise that resolves to a Response object
   */
  async SubmitInsight(insight: InsightData) {
    const token = await this.integrationHandler.getAccessToken()
    this.PerformChecks(token)
    insight.setEndUserO2Id(getOxygenID(token))
    this.RecordLog(
      'SubmitInsight',
      JSON.stringify({ insight: insight }),
      LevelFilter.INFO
    )
    return await RestClient.SubmitInsight(insight, token)
  }

  // gRPC API exports
  /**
   *
   * @param message - Message to be sent to the gRPC server
   * @param messageData - Message data to be sent to the gRPC server
   * @returns - success or failure
   */
  async ProcessMessage(message: string, messageData: JSON) {
    if (this.state.gRPCStatus.isServerAvailable) {
      try {
        return await GatewayClient.ProcessMessage(
          message,
          JSON.stringify(messageData)
        )
      } catch (error) {
        console.log(
          'Error while ProcessMessage: %s , Message: %s',
          error,
          message
        )
        this.state.gRPCStatus.isServerAvailable = false
        // Initiate gRPC sever self heal check loop here
        return error
      }
    }
  }

  /**
   *
   * @param title - Title for the log
   * @param content - Content for the log
   * @returns - nothing
   */
  async RecordLog(title: string, content: any, levelFilter: any) {
    // This will never get executed if the gRPC server is not available
    // Or is_idc_required is false
    if (this.state.gRPCStatus.isServerAvailable) {
      try {
        var token = await this.integrationHandler.getAccessToken()
        var sessionID = await this.integrationHandler.getSessionId()
        var contentObj = {
          content: content,
          title: title,
          sessionID: sessionID,
          product_id: this.integrationHandler.product_id,
          product_name: this.integrationHandler.product_name,
          product_version: this.integrationHandler.product_version,
        }
        var contentString = JSON.stringify(contentObj)
        return await GatewayClient.RecordLog(
          title,
          contentString,
          JSON.stringify({ OxygenID: getOxygenID(token) }),
          levelFilter
        )
      } catch (error) {
        console.log('Error while RecordLog: %s , Title: %s', error, title)
        this.state.gRPCStatus.isServerAvailable = false
        // Initiate gRPC sever self heal check loop here
        return error
      }
    }
    return
  }
}
