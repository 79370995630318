import { Adp } from '@adsk/adp-web-analytics-sdk';
import envConfig from 'environmentConfig';
import { events } from '../constants/analyticsConfig';
import host from '../services/HostWrapper';

class AdpTracking {
  constructor() {
    this.adpInstance = null;
  }

  initializeTrackingClient() {
    const { autodeskDataPlatform } = envConfig.get();
    const regFacets = {
      name: 'SharedViews',
      line_name: 'SharedViews',
      id_provider: 'appkey',
      id: autodeskDataPlatform.clientID,
    };
    this.adpInstance = Adp().load(regFacets).setEnv(autodeskDataPlatform.env);

    host
      .getOAuth2Token()
      .then((token) => this.adpInstance?.loadUserConsentPreferences(token))
      .then(() => {
        this.adpInstance?.identify();
      });
  }

  trackEvent(event, facets) {
    this.adpInstance.track(event, facets);
  }

  trackComponentEvent(name) {
    this.trackEvent(events.CLICK_COMPONENT, {
      name,
    });
  }
}

const adpInstance = new AdpTracking();
export default adpInstance;
