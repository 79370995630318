"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.addSchemaToClientWithInstance = void 0;
const isApiGraphQLProviderConfig_1 = require("./internals/utils/runtimeTypeGuards/isApiGraphQLProviderConfig");
const internals_1 = require("./internals");
const server_1 = require("./internals/server");
// TODO: separate import path
function addSchemaToClientWithInstance(client, params, getInternals) {
    const apiGraphqlConfig = params.config?.API?.GraphQL;
    if ((0, isApiGraphQLProviderConfig_1.isApiGraphQLConfig)(apiGraphqlConfig)) {
        (0, internals_1.upgradeClientCancellation)(client);
        client.models = (0, server_1.generateModelsProperty)(client, params, getInternals);
        client.enums = (0, internals_1.generateEnumsProperty)(apiGraphqlConfig);
        client.queries = (0, internals_1.generateCustomQueriesProperty)(client, apiGraphqlConfig, getInternals);
        client.mutations = (0, internals_1.generateCustomMutationsProperty)(client, apiGraphqlConfig, getInternals);
    }
    return client;
}
exports.addSchemaToClientWithInstance = addSchemaToClientWithInstance;
