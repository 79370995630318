export const CONSTANTS = {
  // Change this to "api_key" if debugging using API_KEY
  AUTH_MODE: 'lambda',

  // appSync constants
  PRODUCTION_REST_ENDPOINT:
    'https://reporting-api.eccr.autodesk.com/insights/v1',
  STAGE_REST_ENDPOINT:
    'https://reporting-api.eccr-stg.autodesk.com/insights/v1',

  PRODUCTION_GRAPHQL_ENDPOINT: 'https://insight-api.eccr.autodesk.com/graphql',
  STAGE_GRAPHQL_ENDPOINT: 'https://insight-api.eccr-stg.autodesk.com/graphql',

  // gRPC gateway constants
  GATEWAY_START_SCAN_PORT: 61417, // Start port for scanning for gateway from this port as gRPC server likly run on 61416 and gateway will never gate chance to run on 61416
  GATEWAY_END_SCAN_PORT: 61436,
  GATEWAY_CHECK_LOOP_INTERVAL: 60000, // This is heartbeat interval <3
  GATEWAY_ENDPOINT: 'http://127.0.0.1:',
  GATEWAY_SIGNAL_TIMEOUT: 2500,
  GATEWAY_CONNECTION_DELAY: 10000, // 10 seconds delay before starting the port scan as it might take some time for gRPC server to spin up
}

export enum LogLevel {
  OFF = 0,
  ERROR = 1,
  WARN = 2,
  INFO = 3,
  DEBUG = 4,
  TRACE = 5,
}

export enum ENV {
  STAGE,
  PRODUCTION,
}

export const JSON_FIELDS = ['fragments', 'chartData', 'jsonValues','content']

export interface responseStatus {
  status: string
  message: string
}

export enum grpcStatusCode {
  OK = 0,
  CANCELLED = 1,
  UNKNOWN = 2,
  INVALID_ARGUMENT = 3,
  DEADLINE_EXCEEDED = 4,
  NOT_FOUND = 5,
  ALREADY_EXISTS = 6,
  PERMISSION_DENIED = 7,
  RESOURCE_EXHAUSTED = 8,
  FAILED_PRECONDITION = 9,
  ABORTED = 10,
  OUT_OF_RANGE = 11,
  UNIMPLEMENTED = 12,
  INTERNAL = 13,
  UNAVAILABLE = 14,
  DATA_LOSS = 15,
  UNAUTHENTICATED = 16,
}
