"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscriptionFactory = void 0;
const rxjs_1 = require("rxjs");
const APIClient_1 = require("../APIClient");
function subscriptionFactory(client, modelIntrospection, model, operation, getInternals) {
    const { name } = model;
    const subscription = (args) => {
        const query = (0, APIClient_1.generateGraphQLDocument)(modelIntrospection, model, operation, args);
        const variables = (0, APIClient_1.buildGraphQLVariables)(model, operation, args, modelIntrospection);
        const auth = (0, APIClient_1.authModeParams)(client, getInternals, args);
        const headers = (0, APIClient_1.getCustomHeaders)(client, getInternals, args?.headers);
        const observable = client.graphql({
            ...auth,
            query,
            variables,
        }, headers);
        return observable.pipe((0, rxjs_1.map)((value) => {
            const [key] = Object.keys(value.data);
            const data = value.data[key];
            const flattenedResult = (0, APIClient_1.flattenItems)(modelIntrospection, name, data);
            if (flattenedResult === null) {
                return null;
            }
            else if (args?.selectionSet) {
                return flattenedResult;
            }
            else {
                const [initialized] = (0, APIClient_1.initializeModel)(client, name, [flattenedResult], modelIntrospection, auth.authMode, auth.authToken);
                return initialized;
            }
        }));
    };
    return subscription;
}
exports.subscriptionFactory = subscriptionFactory;
